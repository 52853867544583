import {
  Create, useNotify, useRedirect, useRefresh
} from 'react-admin';
import JobItemForm from './JobItemForm';

const JobItemCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`工程項目已建立`);
    redirect('/admin/worker-jobs/'+data?.job_id+'/show');
    refresh();
  }; 
  return (
    <Create {...props} onSuccess={onSuccess}>
      <JobItemForm />
    </Create>
  )
};

export default JobItemCreate;