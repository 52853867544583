import {
  Create, useNotify, useRefresh, useRedirect
} from 'react-admin';
import TransactionCreateForm from './TransactionCreateForm';
import OrderTransactionForm from './TransactionCreateForm';

const TransactionCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`交易記錄已建立`);
    redirect('/admin/orders/' + data?.orderId + '/show/payments');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <TransactionCreateForm />
    </Create>
  )
};

export default TransactionCreate;