import React, { useState, useEffect } from 'react';
import { AutocompleteInput} from 'react-admin';
import axios from 'helpers/axios';

const RemoteAutocompleteInput = (props) => {
    const [options, setOptions] = useState([]);
    const [init, setInit] = useState(false);
    const remoteurl = props.remoteurl;
    useEffect(() => {
        if (!init){
            axios.get(axios.addApiUrl(remoteurl))
            .then((response) => {
                setOptions(response.data)
            })
            .then(setInit(true));
        }
    },[init, remoteurl, options]);
    return (
        <AutocompleteInput {...props}
            choices = {
                options.map( row => {
                    return {
                        ...row,
                        id: row[props.idproperty],
                        name: row[props.valueproperty],
                    }
                })
            }
        />
    )
};

export default RemoteAutocompleteInput;