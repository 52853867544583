import RemoteSelectInput from 'components/RemoteSelectInput';
import JobItemWorkerServiceItemRemoteSelectInput from 'components/jobItem/JobItemWorkerServiceItemRemoteSelectInput';
import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

const JobItemForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="job_id" label="工程ID" disabled />
      <JobItemWorkerServiceItemRemoteSelectInput
        source="worker_service_item_id"
        label="工程項目"
        remoteurl={`/value/job-item-worker-service-items?job_id=${props.record?.job_id}`}
        idproperty="id"
        valueproperty="itemName"
        validate={required()}
        alwaysOn
        fullWidth={false}
        />
      <TextInput source="qty" label="數量" />
      <TextInput source="price" label="成本" />
      <RemoteSelectInput 
        source="status" 
        label="狀態" 
        remoteurl="/value/item-status-code"
        idproperty = "code"
        valueproperty = "name"
        validate={required()}
        alwaysOn
      />
    </SimpleForm>
  )
    ;
}

export default JobItemForm;