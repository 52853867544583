// in src/Dashboard.js
import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Tab, Tabs, Tooltip } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { Error, Loading, ShowButton, useGetList, useQuery, useQueryWithStore } from 'react-admin';
import myAxios from 'helpers/axios';
import OrderList from './OrderList';
import PickedUpOrderList from './PickedUpOrderList';
import PendingPickUpList from './PendingPickUpList';
import WaitingNroOrderList from './WaitingNroOrderList';
import CompletedNotVerifyOrderList from './CompletedNotVerifyOrderList';
import InProgressOrderList from './InProgressOrderList';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const IntegratedDashboard = () => {
  const [trendIndex, setTrendIndex] = React.useState({
    orderTotal: 0,
    orderSum: 0,
    jobSum: 0,
    profit: 0,
    pOrderTotal: 0,
    pJobSum: 0,
    pOrderSum: 0,
    pProfit: 0,
  });
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {
    ids: pickedUpOrderIds,
    data: pickedUpOrder,
    loading: pickedUpOrderLoading,
    error,
    loaded: pickedUpOrderLoaded,
    total: pickedUpOrderTotal,
  } = useGetList('admin/dashboard/picked-up', { page: 1, perPage: 50 }, { field: 'created_at', order: 'asc' }, {});
  const {
    ids: pendingPickOrderIds,
    data: pendingPickOrder,
    loading: pendingPickOrderLoading,
    error: pendingPickOrderError,
    loaded: pendingPickOrderLoaded,
    total: pendingPickOrderTotal,
  } = useGetList(
    'admin/dashboard/pending-pickup-order',
    { page: 1, perPage: 50 },
    { field: 'created_at', order: 'asc' },
    {}
  );
  const {
    ids: waitingNroOrderIds,
    data: waitingNroOrder,
    loading: waitingNroOrderLoading,
    error: waitingNroOrderError,
    loaded: waitingNroOrderLoaded,
    total: waitingNroOrderTotal,
  } = useGetList('admin/dashboard/waiting-nro', { page: 1, perPage: 50 }, { field: 'created_at', order: 'asc' }, {});
  const {
    ids: completeNotVerifyOrderIds,
    data: completeNotVerifyOrder,
    loading: completeNotVerifyOrderLoading,
    error: completeNotVerifyOrderError,
    loaded: completeNotVerifyOrderLoaded,
    total: completeNotVerifyOrderTotal,
  } = useGetList(
    'admin/dashboard/completed-not-verify',
    { page: 1, perPage: 50 },
    { field: 'created_at', order: 'asc' },
    {}
  );
  const {
    ids: inProgressOrderIds,
    data: inProgressOrder,
    loading: inProgressOrderLoading,
    error: inProgressOrderError,
    loaded: inProgressOrderLoaded,
    total: inProgressOrderTotal,
  } = useGetList('admin/dashboard/in-progress', { page: 1, perPage: 50 }, { field: 'created_at', order: 'asc' }, {});

  const fetchTrendIndex = React.useCallback(async () => {
    return myAxios.get(myAxios.addApiUrl('/admin/dashboard/trend-index')).then(async (res) => {
      setTrendIndex(res.data);
    });
  }, []);

  const getPercentageChange = React.useMemo(() => {
    return (newValue, originValue) => {
      return ((newValue - originValue) / originValue) * 100;
    };
  }, []);

  const TrendingLabel = ({ newValue, originValue }) => {
    const percentageChange = getPercentageChange(newValue, originValue);
    if (!newValue || !originValue || originValue === 0) return <p>載入中</p>;
    if (percentageChange > 0) {
      return (
        <Tooltip title={originValue}>
          <p className="cardTrend raise">
            <TrendingUpIcon />
            上升 {Math.round(percentageChange)}% <span>自上月起</span>
          </p>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={originValue}>
          <p className="cardTrend down">
            <TrendingDownIcon />
            下降 {Math.round(percentageChange)}% <span>自上月起</span>
          </p>
        </Tooltip>
      );
    }
  };

  React.useEffect(() => {
    fetchTrendIndex();
  }, [fetchTrendIndex]);

  return (
    <div>
      <div className="cardContainer">
        <Card className="card">
          <p className="cardTitle">本月收入</p>
          <p className="cardFigure">${trendIndex?.orderSum}</p>
          <TrendingLabel newValue={trendIndex?.orderSum} originValue={trendIndex?.pOrderSum} />
        </Card>
        <Card className="card">
          <p className="cardTitle">本月訂單</p>
          <p className="cardFigure">{trendIndex?.orderTotal}單</p>
          <TrendingLabel newValue={trendIndex?.orderTotal} originValue={trendIndex?.pOrderTotal} />
        </Card>
        <Card className="card">
          <p className="cardTitle">本月師傅開支</p>
          <p className="cardFigure">${trendIndex?.jobSum}</p>
          <TrendingLabel newValue={trendIndex?.jobSum} originValue={trendIndex?.pJobSum} />
        </Card>
        <Card className="card">
          <p className="cardTitle">本月利潤</p>
          <p className="cardFigure">${trendIndex?.profit}</p>
          <TrendingLabel newValue={trendIndex?.profit} originValue={trendIndex?.pProfit} />
        </Card>
      </div>
      <div className="tabContainer">
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab className="tabLabel" label="訂單資訊" />
          <Tab className="tabLabel" label={`未接單`} />
          <Tab className="tabLabel" label={`未出發`} />
          <Tab className="tabLabel" label={`待報價`} />
          <Tab className="tabLabel" label={`完成未驗證`} />
          <Tab className="tabLabel" label={`進行中`} />
        </Tabs>

        <Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Box className="orderInfoCardContainer">
              <Card className="orderInfoCard c1" onClick={() => setTabValue(1)}>
                <p className="orderInfoCard-figure">{pendingPickOrder ? pendingPickOrderIds.length : 0}</p>
                <p className="orderInfoCard-heading">未接訂單</p>
              </Card>
              <Card className="orderInfoCard c2" onClick={() => setTabValue(2)}>
                <p className="orderInfoCard-figure">{pickedUpOrder ? pickedUpOrderIds.length : 0}</p>
                <p className="orderInfoCard-heading">未出發</p>
              </Card>
              <Card className="orderInfoCard c3" onClick={() => setTabValue(3)}>
                <p className="orderInfoCard-figure">{waitingNroOrder ? waitingNroOrderIds.length : 0}</p>
                <p className="orderInfoCard-heading">待報價</p>
              </Card>
              <Card className="orderInfoCard c4" onClick={() => setTabValue(4)}>
                <p className="orderInfoCard-figure">{completeNotVerifyOrder ? completeNotVerifyOrderIds.length : 0}</p>
                <p className="orderInfoCard-heading">完成未驗證</p>
              </Card>
              <Card className="orderInfoCard c5" onClick={() => setTabValue(5)}>
                <p className="orderInfoCard-figure">{inProgressOrder ? inProgressOrderIds.length : 0}</p>
                <p className="orderInfoCard-heading">進行中</p>
              </Card>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {pendingPickOrderLoading ? <Loading /> : <PendingPickUpList />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            {pickedUpOrderLoading ? <Loading /> : <PickedUpOrderList />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={3}>
            {waitingNroOrderLoading ? <Loading /> : <WaitingNroOrderList />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={4}>
            {completeNotVerifyOrderLoading ? <Loading /> : <CompletedNotVerifyOrderList />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={5}>
            {inProgressOrderLoading ? <Loading /> : <InProgressOrderList />}
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default IntegratedDashboard;
