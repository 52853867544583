import Config from 'Config';
import RemoteSelectInput from 'components/RemoteSelectInput';
import React from 'react';
import {
    BooleanField,
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

const SiteWhatsappForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="site_id" label="分店"  validate={required()}/>
      <RadioButtonGroupInput
        source="whatsapp_receiver_type"
        label="類型"
        choices={[
          { id: '1', name: '電話' },
          { id: '2', name: '群組' },
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          console.log(formData?.whatsapp_receiver_type);
          if (!formData.whatsapp_receiver_type) return null;
          if (formData?.whatsapp_receiver_type == 2) {
            return (
                <RemoteSelectInput
                    source="receiver"
                    label="Whatsapp群組"
                    remoteRawUrl={`https://api.ultramsg.com/${Config.ultraMsgInstanceId}/groups`}
                    idproperty = "id"
                    valueproperty = "name"
                    validate={required()}
                    alwaysOn
                />
            )
          }
          return (
            <TextInput source="receiver" label= "Whatsapp電話" validate={required()}/>
          )
        }}
      </FormDataConsumer>
      <RemoteSelectInput
        source="type_id"
        label="內容"
        remoteurl="/value/whatsapp-type"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <BooleanInput source="active" label="Active" defaultValue={1}/>
    </SimpleForm>
  )
    ;
}

export default SiteWhatsappForm;