import myAxios from 'helpers/axios';
import _ from 'lodash';
import React, { useMemo } from 'react';
import ContentCreate from '@material-ui/icons/Create';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  CardActions,
  ListButton,
  RefreshButton,
  ReferenceField,
  ReferenceArrayField,
  Datagrid,
  EditButton,
  useShowController,
  ShowButton,
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';

const WorkerStatementShowActions = ({ basePath, data }) => {
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      <RefreshButton />
    </CardActions>
  );
};

const WorkerStatementShow = (props) => {
  const { record } = useShowController(props);
  const [downloading, setDownloading] = React.useState(false);
  const downloadPdf = async () => {
    setDownloading(true);
    try {
      myAxios
        .get(myAxios.addApiUrl(`/file/pdf/worker-statement?statement_id=${record.id}`), {
          responseType: 'blob',
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          const pdfWindow = window.open();
          pdfWindow.location.href = fileURL;
          setDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloading(false);
        });
    } catch (error) {
      return { error };
    }
  };
  return (
    <Show actions={<WorkerStatementShowActions />} {...props}>
      <SimpleShowLayout>
        <ReferenceField label="師傅" source="worker_id" reference="admin/worker-users" link="show">
          <TextField source="full_name" />
        </ReferenceField>
        <FunctionField
          label="日期"
          render={(record) => {
            return (
              <div>
                {record?.from_date} - {record?.to_date}
              </div>
            );
          }}
        />
        <TextField source="ref" label="Ref" />
        <FunctionField
          label="狀態"
          render={(record) => {
            if (record.status === 'generated') {
              return <div>核對中 - {record?.generated_at}</div>;
            }
            if (record.status === 'issued') {
              return <div>發佈中 - {record?.issued_at}</div>;
            }
            if (record.status === 'settled') {
              return (
                <div>
                  <div>已結算 - {record?.settled_at}</div>
                  <a href={record?.statementImg?.src} target="_blank" rel="noreferrer">
                    <img src={record?.statementImg?.src} alt="statement" style={{ maxWidth: 150 }} />
                  </a>
                </div>
              );
            }
          }}
        />
        <FunctionField
          label="平台費"
          render={(record) => {
            return <div>{record?.fee}%</div>;
          }}
        />
        <ReferenceArrayField source="jobIds" label="訂單" reference="admin/worker-jobs">
          <Datagrid empty="沒有訂單">
            <TextField source="order.ref" label="訂單編號" />
            <ReferenceField source="order.site_id" label="分店" reference="admin/sites">
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label="應發工資"
              render={(record) => {
                return <div>{_.sumBy(record.payments, (o) => (o.disamount != null ? o.disamount : o.amount))}</div>;
              }}
            />
            <FunctionField
              label="已發工資"
              render={(record) => {
                return <div>{_.sumBy(record.payments, (o) => _.sumBy(o.transactions, 'amount'))}</div>;
              }}
            />
            <TextField source="order.worker_statement_date" label="日期" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
        <EditButton label="更新工資單" />
        <CardActions>
          <Button
            onClick={downloadPdf}
            disabled={downloading}
            mode="contained"
            style={{ backgroundColor: '#fff', color: '#333' }}
          >
            {downloading ? (
              <CircularProgress />
            ) : (
              <>
                <PictureAsPdfIcon />
                查看工資單
              </>
            )}
          </Button>
        </CardActions>
      </SimpleShowLayout>
    </Show>
  );
};

export default WorkerStatementShow;
