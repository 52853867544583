import React from "react";
import {
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PaymentForm from "./PaymentForm";

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const OrderEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const PaymentEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`收付款已更新`);
    redirect('/admin/orders/' + data.orderId + '/show/payments');
    refresh();
  };
  return (
    <Edit {...props} undoable={false} onSuccess={onSuccess}>
      <PaymentForm toolbar={<OrderEditToolbar />} />
    </Edit>
  )
};

export default PaymentEdit;