import React from 'react';
import { Link, Button, useNotify, useUpdate, BooleanInput } from 'react-admin';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Dialog from '@material-ui/core/Dialog';
import MuiButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import Save from '@material-ui/icons/Save';
import MuiTextField from '@material-ui/core/TextField';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';
import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

const TextInput = withStyles({
  root: {
    margin: '16px 0px',
  },
})(MuiTextField);

const Checkbox = withStyles({
  root: {
    margin: '16px 0px',
  },
})(MuiCheckbox);

const UpdateAssignedWorkerModal = ({ record }) => {
  const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleSetModal = React.useCallback(() => {
    setModalOpen(!modalOpen);
  }, []);
  const [update, { loading }] = useUpdate('admin/orders', record?.id); // Access dataProvider API call
  const notify = useNotify(); // Initialize notify object to send notification to dashboard
  const handleSubmit = async (values) => {
    update(
      { payload: { data: values, previousData: record } },
      {
        onSuccess: ({ data }) => {
          setModalOpen(false);
          notify('ra.notification.updated', 'info', { smart_count: 1 }); // Default onSuccess function
        },
        onFailure: ({ error }) => {
          notify('Something went wrong.', 'error');
        },
      }
    );
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">加入/更改接單師傅</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize
            subscription={defaultSubscription}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field name="assignedWorker">
                    {(props) => (
                      <div>
                        <WorkerRemoteSelectInput
                          variant="filled"
                          defaultOrderCatType={record?.orderCatType?.code || ''}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          label="選擇更換師傅"
                          remoteurl="/value/workers"
                          idproperty="id"
                          valueproperty="label"
                          alwaysOn
                        />
                      </div>
                    )}
                  </Field>
                  {record?.job_id != null ? (
                    <Field name="remove_worker">
                      {(props) => <BooleanInput source="remove_worker" label="取消現有師傅" />}
                    </Field>
                  ) : null}
                  {record?.job_id == null || defaultSubscription.pristine ? (
                    <Field name="allow_dispatch">
                      {(props) => (
                        <div>
                          <BooleanInput source={props.input.name} label="重新派送至師傅App" />
                        </div>
                      )}
                    </Field>
                  ) : null}
                  <MuiButton variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                    <Save style={{ marginRight: '8px' }} />
                    確認
                  </MuiButton>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
      <Button component={Link} onClick={() => handleSetModal()} label="加入/更改接單師傅">
        <SwapCallsIcon />
      </Button>
    </>
  );
};

export default UpdateAssignedWorkerModal;
