import React, { useState, useEffect } from 'react';
import { SelectInput } from 'react-admin';
import axios from 'helpers/axios';
import { useFormState } from 'react-final-form';
import { stringify } from 'query-string';

const OrderItemCompanyServiceItemRemoteSelectInput = (props) => {
  const [options, setOptions] = useState([]);
  const [init, setInit] = useState(false);
  const remoteurl = props.remoteurl;
  const { values, dirtyFields } = useFormState();
  useEffect(() => {
    if (!init) {
      axios.get(axios.addApiUrl(remoteurl))
        .then((response) => {
          setOptions(response.data)
        })
        .then(setInit(true));
    }
  }, [init, remoteurl, options]);
  useEffect(() => {
    let query = {};
    if (values.order_id) {
      query = {
        ...query,
        order_id: values.order_id,
      }
    }
    axios.get(axios.addApiUrl(`${remoteurl}?${stringify(query)}`))
      .then((response) => {
        setOptions(response.data)
      });

  }, [dirtyFields.order_id, values.order_id, remoteurl]);
  return (
    <>
      {
        values.order_id === undefined ?
          <div>無效訂單資料</div>
        :
          <SelectInput {...props}
            choices={
              options.map(row => {
                console.log(row);
                return {
                  ...row,
                  id: row[props.idproperty],
                  name: `${row[props.valueproperty]} HKD${row['charge_price']}`,
                }
              })
            }
          />
      }
    </>
  )
};

export default OrderItemCompanyServiceItemRemoteSelectInput;