import React, { useState, useEffect, useRef } from 'react';
import { SelectInput } from 'react-admin';
import axios from 'helpers/axios';
import { useFormState } from 'react-final-form';

const SubCategoryRemoteSelectInput = (props) => {
    const [options, setOptions] = useState([]);
    const [init, setInit] = useState(false);
    const remoteurl = props.remoteurl;
    const { values, dirtyFields } = useFormState();
    useEffect(() => {
        if (!init){
            axios.get(axios.addApiUrl(remoteurl))
            .then((response) => {
                setOptions(response.data)
            })
            .then(setInit(true));
        }
    },[init, remoteurl, options]);
    useEffect(()=> {
        if (values.category_code) {
           // let apiUrl = companyId ?  : axios.addApiUrl(remoteurl);
            axios.get(axios.addApiUrl(`${remoteurl}?parent_cat_code=${values.category_code}`))
            .then((response) => {
                setOptions(response.data)
            })
        } 
    }, [dirtyFields.category_code, values.category_code]);
    useEffect(()=> {
        if (props.defaultParentCatCode) {
           // let apiUrl = companyId ?  : axios.addApiUrl(remoteurl);
            axios.get(axios.addApiUrl(`${remoteurl}?parent_cat_code=${props.defaultParentCatCode}`))
            .then((response) => {
                setOptions(response.data)
            })
        } 
    }, [props.defaultParentCatCode]);
    return (
        <SelectInput {...props}
            choices = {
                options.map( row => {
                    return {
                        ...row,
                        id: row[props.idproperty],
                        name: row[props.valueproperty],
                    }
                })
            }
        />
    )
};

export default SubCategoryRemoteSelectInput;