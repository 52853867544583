import React from 'react';
import { useDelete, useRefresh } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { MuiDeleteButton } from './buttons';
import MuiDialog from 'components/mui/MuiDialog';

const ConfirmDeleteButton = ({ resource, id, dialogTitleText, children }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [deleteOne, { error, loading, loaded }] = useDelete(resource, id);
  const refresh = useRefresh();
  return (
    <div>
      {loading ? <CircularProgress /> : <MuiDeleteButton onClick={() => setShowDialog(true)} />}
      <MuiDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        title={dialogTitleText}
        handleConfirmAction={() => {
          deleteOne(resource, id);
          refresh();
        }}
        error={error}
        dialogContentChildren={children}
      />
    </div>
  );
};

export default ConfirmDeleteButton;
