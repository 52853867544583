import React from 'react';
import { Edit, Toolbar, SaveButton, DeleteButton, useNotify, useRefresh, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TagEditForm from './TagEditForm';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const TagEditToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const TagEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`分店標籤已更新`);
    redirect(`/admin/tags/${data.id}/show`);
    refresh();
  };
  return (
    <Edit onSuccess={onSuccess} {...props} undoable={false}>
      <TagEditForm toolbar={<TagEditToolbar />} />
    </Edit>
  );
};

export default TagEdit;
