import React from "react";
import {
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import NroRequestDetailsForm from "./NroRequestDetailsForm";

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const NroRequestDetailsEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const NroRequestDetailsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`項目已更新`);
    redirect(`/admin/non-regular-order-item-requests/${data.request_id}`);
    refresh();
  };
  return (
  <Edit onSuccess={onSuccess} {...props} undoable={false} >
    <NroRequestDetailsForm toolbar={<NroRequestDetailsEditToolbar />} />
  </Edit>
  )
};

export default NroRequestDetailsEdit;