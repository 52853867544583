import React, { Fragment, cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  BulkDeleteButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ReferenceField,
  CreateButton,
  EditButton,
} from 'react-admin';

const WorkerStatementListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const WorkerStatementBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const WorkerStatementList = (props) => {
  return (
    <List
      {...props}
      actions={<WorkerStatementListActions />}
      bulkActionButtons={<WorkerStatementBulkActionButtons />}
      sort={{ field: 'created_at', Job: 'ASC' }}
    >
      <Datagrid rowClick={false}>
        <TextField label="Ref" source="ref" />
        <ReferenceField label="師傅" source="worker_id" reference="admin/worker-users" link="show">
          <TextField source="full_name" />
        </ReferenceField>
        <FunctionField
          label="日期"
          render={(record) => {
            console.log(record.from_date);
            return (
              <div>
                {record.from_date}至{record.to_date}
              </div>
            );
          }}
        />
        <FunctionField
          label="平台費"
          render={(record) => {
            return <div>{record.fee}%</div>;
          }}
        />
        <FunctionField
          label="狀態"
          render={(record) => {
            if (record.status === 'generated') {
              return <div>核對中 - {record?.generated_at}</div>;
            }
            if (record.status === 'issued') {
              return <div>發佈中 - {record?.issued_at}</div>;
            }
            if (record.status === 'settled') {
              return <div>已結算 - {record?.settled_at}</div>;
            }
          }}
        />
        <ShowButton label="詳情" />
        <EditButton label="更新" />
      </Datagrid>
    </List>
  );
};

export default WorkerStatementList;
