import {
  Create, useNotify, useRedirect, useRefresh
} from 'react-admin';
import SiteWhatsappForm from './SiteWhatsappForm';

const SiteWhatsappCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`分店:${data?.site?.name}的Whatsapp通知已建立`);
    redirect('/admin/sites/'+data?.site_id+'/show/1');
    refresh();
  }; 
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SiteWhatsappForm />
    </Create>
  )
};

export default SiteWhatsappCreate;