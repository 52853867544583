import React, { useState, useEffect } from 'react';
import { AutocompleteArrayInput, AutocompleteInput } from 'react-admin';
import axios from 'helpers/axios';

const RemoteAutocompleteArrayInput = (props) => {
  const [options, setOptions] = useState([]);
  const [init, setInit] = useState(false);
  const remoteurl = props.remoteurl;
  useEffect(() => {
    if (!init) {
      axios
        .get(axios.addApiUrl(remoteurl))
        .then((response) => {
          setOptions(response.data);
        })
        .then(setInit(true));
    }
  }, [init, remoteurl, options]);
  return (
    <AutocompleteArrayInput
      {...props}
      choices={options.map((row) => {
        return {
          ...row,
          id: row[props.idproperty],
          name: row[props.valueproperty],
        };
      })}
      translateChoice={false}
    />
  );
};

export default RemoteAutocompleteArrayInput;
