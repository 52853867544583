import { convertLocaleDate } from "helpers/localeDate";
import React, { Fragment, cloneElement } from "react";
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton,
    BulkDeleteButton,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    DateField,
    linkToRecord,
    useRecordContext,
    useGetOne,
    ShowButton,
    Button
} from 'react-admin';
import { Link } from 'react-router-dom';

const NroRequestListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};

const NroRequestBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

const OrderField = (props) => {
    const post = useRecordContext(props);
    const { data, loaded } = useGetOne('admin/orders', post.order_id);
    const orderShowPage = linkToRecord('/admin/orders', post.order_id, 'show');
    return loaded ? <Link to={orderShowPage}>{data.ref}</Link> : null;
};

const NroRequestList = (props) => {
    return (
    <List
        {...props}
        actions={<NroRequestListActions />}
        bulkActionButtons={<NroRequestBulkActionButtons />}
        sort={{ field: 'created_at', DefectOrder: 'DESC' }}
    >
        <Datagrid rowClick={false}>
            <OrderField source="order_id" label="訂單ID" />
            <TextField source="order.order_cat_type" label="訂單類別" />
            <TextField source="status" label="狀態" />
            <FunctionField label="建立日期"
                render={record => {
                    const date = new Date(`${record.created_at}`);
                    return (
                        <div>
                            {convertLocaleDate(date)}
                        </div>
                    )
                }}
            />
            <FunctionField label="更新日期"
                render={record => {
                    const date = new Date(`${record.updated_at}`);
                    return (
                        <div>
                            {convertLocaleDate(date)}
                        </div>
                    )
                }}
            />
            <FunctionField render={
                record => {
                return (
                <>
                {record.status === 'pending_add_item' && 
                <React.Fragment>
                    <EditButton label="加入項目" record={record} />
                    <ShowButton label="查看" record={record} />
                </React.Fragment>
                }
                {record.status !== 'pending_add_item' && 
                <React.Fragment>
                    <EditButton label="更新項目" record={record} disabled={record.status === 'approved' || record.status === 'approved_add_company_service_item_only'}/>
                    <ShowButton label="查看" record={record} />
                </React.Fragment>
                }
                </>
                )}
            }/>
        </Datagrid>
    </List>
    )
};

export default NroRequestList;