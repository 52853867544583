import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from 'react-admin';

const ServiceItemCategoryForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" label="項目名稱" required />
      <TextInput source="name_en" label="項目名稱(英)" required />
      <TextInput source="code" label="項目系統代碼" required />
      <ReferenceArrayInput allowEmpty label="子類別" source="subCatIds" reference='code/service-item-sub-categories'>
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="active" label="Active" defaultValue={1}/>
    </SimpleForm>
  )
    ;
}

export default ServiceItemCategoryForm;