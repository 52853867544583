import React, { Fragment, cloneElement } from 'react';
import {
  ReferenceField,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  CloneButton,
  required,
  EmailField,
  NumberField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  ImageField,
  Button,
  Link,
  RefreshButton,
  CardActions,
  ListActions,
  ListButton,
  BooleanField,
  SingleFieldList,
  PasswordInput,
  SelectInput,
  useNotify,
  useRefresh,
  useRedirect,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import BooleanNumField from 'components/BooleanNumField';
import InlineImageField from 'components/InlineImageField';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import SubCategoryRemoteSelectInput from 'components/SubCategoryRemoteSelectInput';
import OrderItemCreate from 'resource/orderItem/OrderItemCreate';
import WorkerServiceItemLogButton from './WorkerServiceItemLogButton';
import { convertLocaleDate } from 'helpers/localeDate';
import ImportBatchItemButton from './ImportBatchItemButton';
import Config from 'Config';
const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const WorkerUserListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const WorkerUserBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const WorkerUserEditToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const WorkerUserForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="username" label="用戶帳號" validate={required()} fullWidth />
    <TextInput source="full_name" label="顯示名稱" validate={required()} fullWidth />
    <PasswordInput source="password" label="密碼" fullWidth />
    <TextInput source="email" label="Email" fullWidth />
    <SelectInput
      source="status"
      label="狀態"
      choices={[
        { id: '10', name: 'Active' },
        { id: '0', name: 'Inactive' },
      ]}
    />
  </SimpleForm>
);

const WorkerUserFilter = (props) => {
  return (
    <Filter {...props}>
      <RemoteAutocompleteInput
        source="order_cat_type"
        label="工作技能"
        remoteurl="/value/order-cat-type"
        idproperty="code"
        valueproperty="name"
        alwaysOn
        fullWidth
      />
      <TextInput source="keyword" label="關鍵字搜尋" alwaysOn />
    </Filter>
  );
};

export const WorkerUserList = (props) => (
  <List
    {...props}
    actions={<WorkerUserListActions />}
    bulkActionButtons={<WorkerUserBulkActionButtons />}
    filters={<WorkerUserFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="username" label="帳戶名稱" />
      <TextField source="full_name" label="師傅名稱" />
      <EmailField source="email" label="Email" />
      <NumberField source="status" label="狀態" />
      <FunctionField
        label="創建日期"
        render={(record) => {
          const date = new Date(`${record.created_at}`);
          return <div>{convertLocaleDate(date)}</div>;
        }}
      />
      <FunctionField
        label="更新日期"
        render={(record) => {
          const date = new Date(`${record.updated_at}`);
          return <div>{convertLocaleDate(date)}</div>;
        }}
      />
      <ShowButton label="詳情" />
      <EditButton label="編輯" />
      <CloneButton label="複製" />
    </Datagrid>
  </List>
);

const AddNewWorkerServiceItemButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/admin/worker-service-items/create',
      // Here we specify the initial record for the create view
      state: { record: { worker_id: record?.id } },
    }}
    label="加入新價目表項目"
  >
    <ChatBubbleIcon />
  </Button>
);

const AddNewWorkerLicenseButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/admin/worker-licenses/create',
      // Here we specify the initial record for the create view
      state: { record: { worker_id: record?.id } },
    }}
    label="加入新牌照"
  >
    <ChatBubbleIcon />
  </Button>
);

const AddNewWorkerSkillsetButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/admin/worker-skillsets/create',
      // Here we specify the initial record for the create view
      state: { record: { worker_id: record?.id } },
    }}
    label="加入新技能"
  >
    <ChatBubbleIcon />
  </Button>
);

const WorkerUserShowActions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <AddNewWorkerServiceItemButton record={data} />
    <AddNewWorkerLicenseButton record={data} />
    <AddNewWorkerSkillsetButton record={data} />
    <ImportBatchItemButton record={data} />
  </CardActions>
);

const WorkerUserWorkerServiceItemFilter = (props) => (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="category_code"
      label="維修類別"
      remoteurl="/value/service-item-category"
      idproperty="code"
      valueproperty="name"
      alwaysOn
    />
    <SubCategoryRemoteSelectInput
      source="sub_category_code"
      label="維修子類別"
      remoteurl="/value/service-item-sub-category"
      idproperty="code"
      valueproperty="name"
      alwaysOn
    />
  </Filter>
);

const WhatsappGroupField = (props) => {
  // console.log(props)
  const record = useRecordContext(props);
  const [result, setResult] = React.useState([]);
  React.useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    var urlencoded = new URLSearchParams();
    urlencoded.append('token', Config.ultraMsgToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch('https://api.ultramsg.com/' + Config.ultraMsgInstanceId + '/groups?' + urlencoded, requestOptions)
      .then((response) => response.json())
      .then((result) => setResult(result))
      .catch((error) => console.log('error', error));
  }, [record?.whatsapp_receiver]);
  if (!result)
    return (
      <div>
        <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-64 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
          {props.label}
        </label>
        <span className="RaLabeled-value-65">未設定 (直接發送至{record.contact_number})</span>
      </div>
    );
  return (
    <div>
      <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-64 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
        {props.label}
      </label>
      <span className="RaLabeled-value-65">{result.find((r) => r.id === record?.whatsapp_receiver)?.name}</span>
    </div>
  );
};

const WorkerServiceItemListActions = (props) => {
  const record = useRecordContext(props);
  return (
    <TopToolbar>
      <WorkerServiceItemLogButton workerId={record.id} />
    </TopToolbar>
  );
};

export const WorkerUserShow = (props) => {
  const { record } = useShowController(props);
  const redirectLiecneseDelete = () => {
    const target = `/admin/worker-users/${record?.id}/show/licenses`;
    return target;
  };
  const redirectServiceItemDelete = () => {
    const target = `/admin/worker-users/${record?.id}/show/service-items`;
    return target;
  };
  return (
    <Show actions={<WorkerUserShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="帳戶資料">
          <EditButton />
          <TextField source="username" label="帳號名稱" />
          <TextField source="full_name" label="師傅名稱" />
          <TextField source="email" label="Email" />
          <FunctionField
            label="創建日期"
            render={(record) => {
              const date = new Date(`${record.created_at}`);
              return <div>{convertLocaleDate(date)}</div>;
            }}
          />
        </Tab>
        <Tab label="個人檔案" path="profile">
          <ReferenceManyField label="個人相片" reference="admin/worker-profile-pics" target="worker_id">
            <Datagrid>
              <ImageField source="data" label="相片" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceField label="師傅個人檔案" reference="admin/worker-profiles" source="profile_id">
            <SimpleShowLayout>
              <TextField source="wid" label="員工編號" />
              <TextField source="first_name" label="姓" />
              <TextField source="last_name" label="名" />
              <TextField source="sex" label="性別" />
              <TextField source="hkid" label="身份證號碼" />
              <TextField source="email" label="Email" />
              <TextField source="contact_number" label="聯絡電話" />
              <TextField source="company_name" label="公司名稱" />
              <TextField source="company_br" label="公司BR" />
              <TextField source="company_address" label="公司地址" />
              <TextField source="bankName" label="銀行" />
              <TextField source="bank_account" label="銀行戶口" />
              <TextField source="bank_account_holder" label="銀行戶口持有人" />
              <TextField source="past_working_experience" label="過往工作經驗" />
              <BooleanNumField source="has_driving_license" label="駕駛執照" />
              <TextField source="pickupMethodName" label="預設接單方法" />
              <TextField source="districtName" label="預設接單地區" />
              <WhatsappGroupField source="whatsapp_receiver" label="接單用Whatsapp群組" />
            </SimpleShowLayout>
          </ReferenceField>
        </Tab>
        <Tab label="價目表" path="service-items">
          <ReferenceManyField
            label="維修項目"
            reference="admin/worker-service-items"
            target="worker_id"
            sort={{ field: 'itemCat', order: 'DESC' }}
          >
            <List
              filters={<WorkerUserWorkerServiceItemFilter />}
              filterDefaultValues={{ worker_id: record?.id }}
              hasCreate={false}
              exporter={false}
              actions={<WorkerServiceItemListActions record={record} />}
            >
              <Datagrid>
                <TextField source="item.category.name" label="維修類別" />
                <ReferenceField source="item_id" reference="code/service-items" label="維修項目">
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="cost" label="成本" />
                <BooleanNumField source="active" />
                <FunctionField
                  label="創建日期"
                  render={(record) => {
                    const date = new Date(`${record.created_at}`);
                    return <div>{convertLocaleDate(date)}</div>;
                  }}
                />
                <FunctionField
                  label="更新日期"
                  render={(record) => {
                    const date = new Date(`${record.updated_at}`);
                    return <div>{convertLocaleDate(date)}</div>;
                  }}
                />
                <EditButton />
                <DeleteButton redirect={redirectServiceItemDelete} />
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
        <Tab label="工人牌照" path="licenses">
          <ReferenceManyField label="牌照" reference="admin/worker-licenses" target="worker_id">
            <Datagrid>
              <TextField source="type" label="牌照類別" />
              <DateField source="expiry_date" label="牌照有效日期" />
              <DateField source="created_at" label="建立日期" />
              <ImageField source="data" title="title" />
              <EditButton />
              <DeleteButton redirect={redirectLiecneseDelete} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="工人技能" path="skillsets">
          <ReferenceManyField label="技能/類別" reference="admin/worker-skillsets" target="worker_id">
            <Datagrid>
              <TextField source="category.name" label="技能/類別" />
              <BooleanNumField source="active" label="Active" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const WorkerUserCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`師傅帳號已建立`);
    redirect(`admin/worker-users/${data?.id}/show`);
    refresh();
  };
  return (
    <Create {...props}>
      <WorkerUserForm onSuccess={onSuccess} />
    </Create>
  );
};

export const WorkerUserEdit = (props) => (
  <Edit {...props} undoable={false}>
    <WorkerUserForm toolbar={<WorkerUserEditToolbar />} />
  </Edit>
);
