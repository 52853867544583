import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import useValue from 'hooks/useValue';
import myAxios from 'helpers/axios';
import { useRefresh } from 'react-admin';
import Config from 'Config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddNewSiteWhatsappButton = ({ record }) => {
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [receiverType, setReceiverType] = React.useState('1');
  const [typeIds, setTypeIds] = React.useState([]);
  const [siteIds, setSiteIds] = React.useState([]);
  const [receiver, setReceiver] = React.useState('');
  const [whatsappGroupOption, setWhatsappGroupOption] = React.useState([]);
  const {
    res: { data: whatsappTypeOption },
  } = useValue('whatsapp-type');
  const {
    res: { data: siteOption },
  } = useValue('sites', { company_id: record?.id });

  React.useEffect(() => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('token', Config.ultraMsgToken);
    myAxios
      .get(`https://api.ultramsg.com/${Config.ultraMsgInstanceId}/groups?${urlencoded}`)
      .then((result) => setWhatsappGroupOption(result.data));
  }, []);

  const refresh = useRefresh();
  const handleSiteChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.find((r) => r == 'all')) {
      setSiteIds(siteOption?.map((element) => element.id));
    } else {
      setSiteIds(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };
  const handleTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setTypeIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = React.useCallback(
    async (event) => {
      //console.log(event.target);
      event.preventDefault();
      setSubmitting(true);
      await myAxios
        .post(myAxios.addApiUrl(`/admin/site-whatsapp/batch-insert`), {
          siteIds: siteIds,
          typeIds: typeIds,
          receiver: receiver,
        })
        .catch((e) => console.error(e));
      setSubmitting(false);
      setOpen(false);
      refresh();
    },
    [receiver, siteIds, typeIds, refresh]
  );
  return (
    <div>
      <Button variant="text" onClick={handleOpen} startIcon={<AddIcon />}>
        加入Whatsapp電話/Group
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={onSubmit}>
            <FormControl style={{ width: 300 }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={receiverType}
                name="receiverType"
                onChange={(e) => setReceiverType(e.target.value)}
              >
                <FormControlLabel value="1" control={<Radio />} label="Whatsapp群組" />
                <FormControlLabel value="2" control={<Radio />} label="電話" />
              </RadioGroup>
            </FormControl>
            <FormControl style={{ width: 300 }}>
              {receiverType == '1' ? (
                <>
                  <InputLabel id="siteIds">Whatsapp Group</InputLabel>
                  <Select
                    labelId="receiver"
                    id="receiver-field"
                    name="receiver"
                    value={receiver}
                    onChange={(event) => setReceiver(event.target.value)}
                    required
                  >
                    {whatsappGroupOption &&
                      whatsappGroupOption?.map((opt) => {
                        return (
                          <MenuItem key={opt.id} value={opt.id}>
                            {opt.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </>
              ) : (
                <TextField
                  name="receiver"
                  value={receiver}
                  onChange={(event) => {
                    setReceiver(event.target.value);
                  }}
                  label="Whatsapp 電話號碼"
                  required
                />
              )}
            </FormControl>
            <br />
            <FormControl style={{ width: 300 }}>
              <InputLabel id="siteIds">分店</InputLabel>
              <Select
                labelId="siteIds"
                id="demo-multiple-name"
                multiple
                name="category"
                value={siteIds}
                onChange={handleSiteChange}
                label="分店"
                multiline={true}
              >
                <MenuItem key="site-all" value="all">
                  加入全部
                </MenuItem>
                {siteOption &&
                  siteOption?.map((site) => {
                    return (
                      <MenuItem key={site?.id} value={site?.id}>
                        {site?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br />
            <FormControl style={{ width: 300, marginBottom: 10 }}>
              <InputLabel id="typeIds">內容</InputLabel>
              <Select
                labelId="typeIds"
                id="typeIds-field"
                multiple
                name="typeIds"
                value={typeIds}
                onChange={handleTypeChange}
                defaultValue={{ typeIds: whatsappTypeOption?.map((r) => r?.id) }}
                label="內容"
              >
                {whatsappTypeOption &&
                  whatsappTypeOption?.map((type) => {
                    return (
                      <MenuItem key={type?.id} value={type?.id}>
                        {type?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br />
            <Button
              startIcon={submitting ? <CircularProgress /> : null}
              disabled={siteIds.length <= 0 || typeIds.length <= 0 || submitting}
              type="submit"
              variant="contained"
            >
              加入
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddNewSiteWhatsappButton;
