import React, { useState, useEffect, useRef } from 'react';
import { SelectInput } from 'react-admin';
import axios from 'helpers/axios';
import { useFormState } from 'react-final-form';

const SiteRemoteSelectInput = (props) => {
    const [options, setOptions] = useState([]);
    const [init, setInit] = useState(false);
    const remoteurl = props.remoteurl;
    const { values, dirtyFields } = useFormState();
    useEffect(() => {
        if (!init){
            axios.get(axios.addApiUrl(remoteurl))
            .then((response) => {
                setOptions(response.data)
            })
            .then(setInit(true));
        }
    },[init, remoteurl, options]);
    useEffect(()=> {
        if (values.company_id) {
           // let apiUrl = companyId ?  : axios.addApiUrl(remoteurl);
            axios.get(axios.addApiUrl(`${remoteurl}?company_id=${values.company_id}`))
            .then((response) => {
                setOptions(response.data)
            })
        }
    }, [dirtyFields.company_id, values.company_id]);
    return (
        <SelectInput {...props}
            choices = {
                options.map( row => {
                    return {
                        ...row,
                        id: row[props.idproperty],
                        name: row[props.valueproperty],
                    }
                })
            }
        />
    )
};

export default SiteRemoteSelectInput;