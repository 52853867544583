import axios from 'axios';
import Config from 'Config';

const defaultRequestConfig = {
};

const handleException = (e) => {
  if (e.response && (e.response.status === 403 || e.response.status === 401)) {
    console.log(e);
    // store.dispatch(AccountActions.logout());
  }
};

const getAuthorization = () => {
  if (localStorage.getItem('token')){ 
    const auth_key = localStorage.getItem('token');
    if (auth_key) {
      return {
        Authorization: `Bearer ${auth_key}`,
      };
    }
  }
  return null;
};

const buildConfig = (config = {}) => {
  const { headers, ...rest } = config;
  return {
    ...defaultRequestConfig,
    ...rest,
    headers: {
      ...getAuthorization(),
      ...headers,
    },
  };
};

const myAxios = {
  addApiUrl: relativePath => (`${Config.apiHost}${relativePath}`),
  get: async (url, config = {}) => {
    try {
      const response = await axios.get(url, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  post: async (url, data, config = {}) => {
    try {
      const response = await axios.post(url, data, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  patch: async (url, data, config = {}) => {
    try {
      const response = await axios.patch(url, data, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  put: async (url, data, config = {}) => {
    try {
      return await axios.put(url, data, buildConfig(config));
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
  delete: async (url, config = {}) => {
    try {
      const response = await axios.delete(url, buildConfig(config));
      return response;
    } catch (e) {
      handleException(e);
      throw e;
    }
  },
};

export default myAxios;
