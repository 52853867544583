import RemoteSelectInput from 'components/RemoteSelectInput';
import {
  BooleanInput,
  DateInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

const WorkerStatementEditForm = (props) => {
  return (
    <SimpleForm {...props}>
      <RemoteSelectInput
        source="status"
        label="狀態"
        remoteurl="/value/statement-status-code"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        translateChoice={false}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <>
              {formData.status === 'issued' ? (
                <div>
                  <DateInput source="issued_at" label="發出日期" required fullwidth />
                </div>
              ) : null}
              {formData.status === 'settled' ? (
                <div>
                  <DateInput source="settled_at" label="結算日期" required fullwidth />
                  {/* <ImageInput
                                source="statementImage"
                                label="月結單相片"
                                accept="image/*"
                                required
                                fullWidth
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput> */}
                </div>
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
      <NumberInput source="fee" label="平台費" fullWidth />
      <TextInput source="issued_statement_google_drive" label="已發佈月結單Google Drive連結" fullwidth />
      <TextInput source="settled_statement_google_drive" label="已結算月結單Google Drive連結" fullwidth />
    </SimpleForm>
  );
};

export default WorkerStatementEditForm;
