import React, {Fragment, cloneElement} from "react";
import { 
    Edit,
    SimpleForm, 
    TextInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    required,
    BooleanInput,
    useRedirect,
    useNotify, 
    useRefresh,
    SelectInput
} from 'react-admin';
import RemoteSelectInput from "components/RemoteSelectInput";
import { makeStyles } from '@material-ui/core/styles';
import Config from "Config";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const WorkerProfileEditToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton  label="儲存"/>
      <DeleteButton label="刪除" undoable = {false} />
    </Toolbar>
  );

const WorkerProfileForm = (props) => (
    <SimpleForm {...props}>
      <TextInput 
        source="first_name"
        label="姓"
        fullWidth
      />
      <TextInput 
        source="last_name"
        label="名"
        fullWidth
      />
      <TextInput 
        source="hkid"
        label="HKID"
        validate={required()}
        fullWidth
      />
      <SelectInput 
        source="sex"
        label="性別"
        validate={required()}
        choices={[
          { id: 'm', name: '男' },
          { id: 'f', name: '女' },
        ]} 
      />
      <TextInput 
        source="contact_number"
        label="聯絡電話"
        fullWidth
      /> 
      {/* <TextInput 
        source="email"
        label="Email"
        fullWidth
      />  */}
      <RemoteSelectInput
        source="bank_code"
        label="戶口所屬銀行"
        remoteurl="/value/bank-code"
        idproperty = "code"
        valueproperty = "name"
        validate={required()}
        alwaysOn
      />
      <TextInput 
        source="bank_account"
        label="銀行戶口"
        fullWidth
      />
      <TextInput 
        source="bank_account_holder"
        label="銀行戶口持有人"
        fullWidth
      />
      <TextInput 
        source="company_name"
        label="公司名稱"
        fullWidth
      />
      <TextInput 
        source="company_br"
        label="公司BR"
        fullWidth
      />
      <TextInput 
        source="company_address"
        label="公司地址"
        fullWidth
      />
      <TextInput 
        source="past_working_experience"
        label="過去工作經驗"
        fullWidth
        multiline
      />
      <BooleanInput 
        source="has_driving_license"
        label="駕駛執照"
        fullWidth
      />
      <RemoteSelectInput
        source="pickup_method"
        label="預設接單方法"
        remoteurl="/value/pickup-method"
        idproperty = "id"
        valueproperty = "name"
        validate={required()}
        alwaysOn
      />
      <RemoteSelectInput
        source="district_code"
        label="預設接單地區"
        remoteurl="/value/district-code"
        idproperty = "code"
        valueproperty = "name"
        validate={required()}
        alwaysOn
      />
      <RemoteSelectInput
        source="whatsapp_receiver"
        label="接單用Whatsapp群組"
        remoteRawUrl={`https://api.ultramsg.com/${Config.ultraMsgInstanceId}/groups`}
        idproperty = "id"
        valueproperty = "name"
        alwaysOn
      />
      <BooleanInput
        source="is_receive_whatsapp"
        label="接收Whatsapp通知"
        fullWidth
      />
      <BooleanInput
        label="active"
        source="active"
        />
    </SimpleForm>
  )

  export const WorkerProfileEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({data}) => {
      notify(`師傅個人檔案已更新`);
      redirect('/admin/worker-users/'+data?.worker_id+'/show/profile');
      refresh();
    }; 
    return ( 
    <Edit {...props} undoable = {false} onSuccess={onSuccess}>
      <WorkerProfileForm toolbar={<WorkerProfileEditToolbar />} />
    </Edit>
    )
  };