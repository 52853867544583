import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';
import TagCreateForm from './TagCreateForm';

const TagCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`分店標籤已建立`);
    redirect('/admin/tags');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <TagCreateForm />
    </Create>
  );
};

export default TagCreate;
