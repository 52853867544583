import React, {Fragment, cloneElement} from "react";
import { 
    ReferenceField,
    List,
    useListContext,
    useShowController,
    sanitizeListRestProps,
    Datagrid, 
    TextField,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm, 
    NumberInput,
    BulkDeleteButton,
    Toolbar,
    TopToolbar,
    CreateButton,
    SaveButton,
    DeleteButton,
    ShowButton,
    EditButton,
    CloneButton,
    required,
    NumberField,
    DateField,
    ReferenceManyField,
    BooleanInput,
    useRedirect,
    useNotify, 
    useRefresh,
    TextInput,
    DateInput,
    ImageField,
    ImageInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import RemoteSelectInput from 'components/RemoteSelectInput';
import InlineImageField from "components/InlineImageField";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const WorkerProfilePicListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增"/>
    </TopToolbar>
  );
};

const WorkerProfilePicBulkActionButtons = props => (
    <Fragment>
      <BulkDeleteButton undoable = {false} {...props} />
    </Fragment>
);

  const WorkerProfilePicEditToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton  label="儲存"/>
      <DeleteButton label="刪除" undoable = {false} />
    </Toolbar>
  );

  const WorkerProfilePicForm = (props) => (
    <SimpleForm {...props}>
      <RemoteSelectInput
        source="worker_id"
        label="師傅"
        remoteurl="/value/workers"
        idproperty = "id"
        valueproperty = "username"
        validate={required()}
        alwaysOn
        editable = {false}
      />
      <ImageField source="data" />
      <ImageInput
        source="file"
        label="相片"
      >
        <ImageField source="src" />
      </ImageInput>
    </SimpleForm>
  )
  
export const WorkerProfilePicEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`項目已更新`);
    redirect('/admin/worker-users/'+data?.worker_id+'/show/profile');
    refresh();
  };  
  return (
    <Edit {...props} undoable = {false} onSuccess={onSuccess}>
      <WorkerProfilePicForm toolbar={<WorkerProfilePicEditToolbar />} />
    </Edit>
  )
};