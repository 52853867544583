import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { required, Button, SaveButton, TextInput, useCreate, useNotify, FormWithRedirect } from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SubCategoryRemoteSelectInput from 'components/SubCategoryRemoteSelectInput';

const ServiceItemQuickCreateButton = (props) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [create, { loading }] = useCreate('code/service-items');
  const notify = useNotify();
  const form = useForm();
  const { values } = useFormState();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    let formData = {
      name: values?.item_name,
      name_en: values?.item_name_en,
      sub_category_code: values?.sub_category_code,
      category_code: values?.category_code,
      list_price: values?.list_price,
      cost: values?.cost,
      description: values?.description,
      description_en: values?.description_en,
      active: 1,
      seq: 0,
      company_id: values?.company_id,
      auto_add_to_company: 1,
    };
    create(
      { payload: { data: formData } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change(`${props.source}`, data.id);
          props.onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  const handleOnChange = (val) => {
    console.log(val);
  };
  return (
    <>
      <Button onClick={handleClick} label="建立新維修項目">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create post">
        <DialogTitle>加入新維修項目</DialogTitle>

        <FormWithRedirect
          resource="code/service-items"
          save={handleSubmit}
          onChange={handleOnChange}
          render={({ handleSubmitWithRedirect, pristine, saving }) => {
            return (
              <>
                <DialogContent>
                  <p>
                    此維修項目會自動加入到以下公司價目表: <br />
                    公司: {values?.companyName}, 維修類別: {values?.categoryName}
                  </p>
                  <SubCategoryRemoteSelectInput
                    source="sub_category_code"
                    label="維修子類別"
                    remoteurl="/value/service-item-sub-category"
                    idproperty="code"
                    valueproperty="name"
                    alwaysOn
                    defaultParentCatCode={values?.category_code}
                    required
                  />
                  <TextInput source="item_name" label="維修項目名稱" fullWidth required />
                  <TextInput source="item_name_en" label="維修項目名稱(英文)" fullWidth required />
                  <TextInput source="list_price" label="參考定價" fullWidth required />
                  <TextInput source="cost" label="參考成本" fullWidth required />
                  <TextInput source="description" label="產品描述" fullWidth />
                  <TextInput source="description_en" label="產品描述(英文)" fullWidth />
                </DialogContent>
                <DialogActions>
                  <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </>
  );
};

export default ServiceItemQuickCreateButton;
