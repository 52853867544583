import React from "react";
import { 
    sanitizeListRestProps,
    Edit,
    SimpleForm, 
    Toolbar,
    SaveButton,
    DeleteButton,
    BooleanInput,
    useRedirect,
    useNotify, 
    useRefresh,
    Create,
    TextInput,
    required,
    useRecordContext
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteSelectInput from "components/RemoteSelectInput";
import RemoteAutocompleteInput from "components/RemoteAutocompleteInput";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

  const WorkerSkillsetEditToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton  label="儲存"/>
      <DeleteButton label="刪除" undoable = {false} />
    </Toolbar>
  );

  const WorkerSkillsetCreateForm = (props) => {
    const record = useRecordContext();
    return (
    <SimpleForm {...props}>
          <TextInput source="worker_id" Label="Worker ID" disabled validate={required()} />
          <RemoteAutocompleteInput
            source="cat_code"
            label="技能/類別"
            remoteurl={`/value/worker-skillset-cat?worker_id=${record?.worker_id}`}
            idproperty="code"
            valueproperty="name"
            alwaysOn
            validate={required()}
          />
          <BooleanInput source="active" label="Active" />
    </SimpleForm>
    )
  }

  const WorkerSkillsetEditForm = (props) => {
    return (
    <SimpleForm {...props}>
          <BooleanInput source="active" label="Active" />
    </SimpleForm>
    )
  }

  export const WorkerSkillsetCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({data}) => {
      notify(`項目已新增`);
      redirect('/admin/worker-users/'+data?.worker_id+'/show/skillsets');
      refresh();
    };   
    return (
      <Create {...props} onSuccess={onSuccess}>
        <WorkerSkillsetCreateForm />
      </Create>
    )};
  
export const WorkerSkillsetEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`項目已更新`);
    redirect('/admin/worker-users/'+data?.worker_id+'/show/skillsets');
    refresh();
  };  
  return (
    <Edit {...props} undoable = {false} onSuccess={onSuccess}>
      <WorkerSkillsetEditForm toolbar={<WorkerSkillsetEditToolbar />} />
    </Edit>
  )
};