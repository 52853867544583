import React from 'react';
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  BooleanInput,
  ImageInput,
  ImageField,
  NumberInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  DateInput,
} from 'react-admin';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import OrderItemCompanyServiceItemRemoteSelectInput from 'components/orderItem/OrderItemCompanyServiceItemRemoteSelectInput';

const OrderEditForm = (props) => {
  const { order_id } = props.record;
  return (
    <SimpleForm {...props}>
      <OrderItemCompanyServiceItemRemoteSelectInput
        source="site_service_item_id"
        label="工程項目"
        remoteurl={`/value/order-item-company-service-items?order_id=${order_id}`}
        idproperty="id"
        valueproperty="itemName"
        validate={required()}
        alwaysOn
        fullWidth={false}
        />
        <NumberInput
        source="qty"
        label="數量"
        defaultValue="1"
        fullWidth={false}
        />
        <NumberInput
        source="price"
        label="自訂價錢 (單價)"
        fullWidth={false}
        />
        <RemoteSelectInput 
          source="status" 
          label="狀態" 
          remoteurl="/value/item-status-code"
          idproperty = "code"
          valueproperty = "name"
          validate={required()}
          alwaysOn
        />
    </SimpleForm>
  )
    ;
}

export default OrderEditForm;