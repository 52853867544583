import React from "react";
import {
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import JobItemForm from "./JobItemForm";

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const JobItemEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const JobItemEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`工程項目已更新`);
    redirect(`/admin/worker-jobs/${data.job_id}/show`);
    refresh();
  };
  return (
  <Edit onSuccess={onSuccess} {...props} undoable={false} >
    <JobItemForm toolbar={<JobItemEditToolbar />} />
  </Edit>
  )
};

export default JobItemEdit;