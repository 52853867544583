import React, { useState, useEffect } from 'react';
import { SelectInput } from 'react-admin';
import axios from 'helpers/axios';
import { useFormState } from 'react-final-form';

const WorkerRemoteSelectInput = (props) => {
    const [options, setOptions] = useState([]);
    const [init, setInit] = useState(false);
    const remoteurl = props.remoteurl;
    const defaultOrderCatType = props.defaultOrderCatType;
    const { values, dirtyFields } = useFormState();
    useEffect(() => {
        if (!init){
            axios.get(axios.addApiUrl(`${remoteurl}?order_cat_type=${defaultOrderCatType}`))
            .then((response) => {
                setOptions(response.data)
            })
            .then(setInit(true));
        }
    },[init, remoteurl, options, defaultOrderCatType]);
    useEffect(()=> {
        if (values.order_cat_type) {
            axios.get(axios.addApiUrl(`${remoteurl}?order_cat_type=${values.order_cat_type}`))
            .then((response) => {
                setOptions(response.data)
            })
        }
    }, [dirtyFields.order_cat_type, values.order_cat_type, remoteurl]);
    return (
        <SelectInput 
            {...props}
            onChange={v => console.log(v.target.value)}
            choices = {
                options.map( row => {
                    return {
                        id: row[props.idproperty],
                        name: row[props.valueproperty],
                    }
                })
            }
        />
    )
};

export default WorkerRemoteSelectInput;