import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import React, { Fragment, cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  BulkDeleteButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ReferenceField,
  CreateButton,
  Filter,
} from 'react-admin';

const CompanyStatementListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const CompanyStatementBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const CompanyStatementFilter = (props) => {
  return (
    <Filter {...props}>
      <RemoteAutocompleteInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        alwaysOn
      />
    </Filter>
  );
};

const CompanyStatementList = (props) => {
  return (
    <List
      {...props}
      actions={<CompanyStatementListActions />}
      bulkActionButtons={<CompanyStatementBulkActionButtons />}
      filters={<CompanyStatementFilter />}
      sort={{ field: 'ref', order: 'DESC' }}
    >
      <Datagrid rowClick={false}>
        <TextField label="Ref" source="ref" />
        <ReferenceField label="公司" source="company_id" reference="admin/companies" link="show">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="日期"
          render={(record) => {
            console.log(record.from_date);
            return (
              <div>
                {record.from_date}至{record.to_date}
              </div>
            );
          }}
        />
        <FunctionField
          label="狀態"
          render={(record) => {
            if (record.status === 'generated') {
              return <div>核對中 - {record?.generated_at}</div>;
            }
            if (record.status === 'issued') {
              return <div>發佈中 - {record?.issued_at}</div>;
            }
            if (record.status === 'settled') {
              return <div>已結算 - {record?.settled_at}</div>;
            }
          }}
        />
        <ShowButton label="詳情" />
      </Datagrid>
    </List>
  );
};

export default CompanyStatementList;
