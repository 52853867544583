import React from 'react';
import { SimpleForm, TextInput, required, NumberInput, BooleanInput, useRecordContext } from 'react-admin';
import OrderItemCompanyServiceItemRemoteSelectInput from 'components/orderItem/OrderItemCompanyServiceItemRemoteSelectInput';
import RemoteSelectInput from 'components/RemoteSelectInput';
import ServiceItemReferenceInput from './ServiceItemReferenceInput';

const OrderItemCreateForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm {...props}>
      <ServiceItemReferenceInput
        filter={{
          order_id: record?.order_id,
          company_id: record?.company_id,
          category_code: record?.category_code,
        }}
        label="訂單項目"
        source="site_service_item_id"
        reference={`admin/company-service-items`}
        allowEmpty
        validate={required()}
        required
        perPage={100}
      />
      <NumberInput source="qty" label="數量" defaultValue="1" fullWidth={false} />
      <RemoteSelectInput
        source="status"
        label="狀態"
        remoteurl="/value/item-status-code"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
      />

      <NumberInput source="price" label="自訂客戶價錢 (單價)" fullWidth={false} />
      <BooleanInput source="insert_job_item" label="自動加入工程項目" defaultValue={1} />
      <NumberInput source="custom_job_item_price" label="自訂師傅價錢 (單價)" />
      <TextInput source="order_id" disabled />
    </SimpleForm>
  );
};

export default OrderItemCreateForm;
