import axios  from "helpers/axios";
import { stringify } from 'query-string';
import React from "react";

const useValue = (value, params) => {
    const [error, setError] = React.useState("");
    const [res, setRes] = React.useState({
        loading: true,
        data : null,
    });
    React.useEffect(() => {
        const getValue = async() => {
            try {
                const data = await axios.get(axios.addApiUrl(`/value/${value}?${stringify(params)}`));
                setRes({
                    loading: false,
                    data: data?.data
                });
            } catch(e) {
                setError(e);
            }
        }
        getValue();
    }, [])

    return {
        res,
        error
    }
}

export default useValue;