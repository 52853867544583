import { Button } from 'react-admin';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import React from 'react';
import myAxios from 'helpers/axios';

const CompanyServiceItemPdf = ({ companyId }) => {
  const [downloading, setDownloading] = React.useState(false);
  const handleClick = React.useCallback(() => {
    setDownloading(true);
    try {
      myAxios
        .get(myAxios.addApiUrl(`/file/pdf/company-price-list?company_id=${companyId}`), {
          responseType: 'blob',
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          const pdfWindow = window.open('about:blank', '_blank');
          //Open the URL on new Window
          pdfWindow.location.href = fileURL;
          setDownloading(false);
        })
        .catch((error) => {
          setDownloading(false);
        });
    } catch (error) {
      return { error };
    }
  }, []);
  return (
    <Button disabled={downloading} onClick={handleClick} label="下載價目表">
      <PictureAsPdf />
    </Button>
  );
};

export default CompanyServiceItemPdf;
