import React, { Fragment, cloneElement } from 'react';
import {
  ReferenceField,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  CloneButton,
  required,
  EmailField,
  NumberField,
  DateField,
  SelectInput,
  ArrayInput,
  AutocompleteArrayInput,
  useRedirect,
  useRefresh,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import useValue from 'hooks/useValue';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const SiteUserListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const SiteUserBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const SiteUserFilter = (props) => (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="company_id"
      label="公司"
      remoteurl="/value/companies"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      alwaysOn
    />
  </Filter>
);

const SiteUserEditToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const SiteUserForm = (props) => {
  const siteOptions = useValue('sites', { company_id: props?.record?.company_id });
  return (
    <SimpleForm {...props}>
      <TextInput source="username" label="用戶登入帳號" validate={required()} fullWidth />
      <TextInput source="full_name" label="用戶名稱" validate={required()} fullWidth />
      <RemoteAutocompleteInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        fullWidth
        disabled
      />
      <TextInput source="password" label="密碼" fullWidth />
      <TextInput source="email" label="Email" fullWidth />
      <small>*保留空白會分配所有分店</small>
      <AutocompleteArrayInput
        translateChoice={false}
        label="分店"
        source="assignSite"
        choices={siteOptions?.res?.data || []}
      />
      {/* <SelectInput source="is_verified" label="認證帳戶" choices={[
      { id: '1', name: '認證' },
      { id: '0', name: '未認證' },
    ]} /> */}
      <BooleanInput source="is_verified" label="認證帳戶" />
      <TextInput source="passcode" label="標籤落單密碼" fullWidth />
      <SelectInput
        source="status"
        label="狀態"
        choices={[
          { id: '10', name: 'Active' },
          { id: '0', name: 'Inactive' },
        ]}
      />
    </SimpleForm>
  );
};

export const SiteUserList = (props) => (
  <List
    {...props}
    filters={<SiteUserFilter />}
    actions={<SiteUserListActions />}
    bulkActionButtons={<SiteUserBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="company_id" reference="admin/companies" label="公司">
        <TextField source="name" label="公司" />
      </ReferenceField>
      <TextField source="username" label="用戶帳號" />
      <TextField source="full_name" label="名稱" />
      <EmailField source="email" />
      <NumberField source="status" />
      <DateField source="created_at" label="創建日期" showTime />
      <DateField source="updated_at" label="更新日期" showTime />
      <ShowButton label="詳情" />
      <EditButton label="編輯" />
      <CloneButton label="複製" />
    </Datagrid>
  </List>
);

export const SiteUserShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField label="公司" source="company_id" reference="admin/companies">
          <TextField source="name" />
        </ReferenceField>
        {record?.assignSite.length ? (
          <ReferenceArrayField label="分店" reference="admin/sites" source="assignSite">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        ) : (
          <FunctionField label="分店" render={() => '全部分店'} />
        )}
        <TextField source="username" label="用戶帳號" />
        <TextField source="full_name" label="用戶名稱" />
        <TextField source="email" label="用戶電郵" />
        <FunctionField label="認證帳戶" render={(record) => (record.is_verified === 1 ? '已認證' : '未認證')} />
        <FunctionField label="狀態" render={(record) => (record.status === 10 ? 'Active' : 'Inactive')} />
      </SimpleShowLayout>
    </Show>
  );
};

export const SiteUserCreate = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const onSuccess = ({ data }) => {
    redirect(`/admin/site-users/${data?.id}/show`);
    refresh();
  };
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SiteUserForm />
    </Create>
  );
};

export const SiteUserEdit = (props) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const onSuccess = ({ data }) => {
    redirect(`/admin/site-users/${data?.id}/show`);
    refresh();
  };
  return (
    <Edit onSuccess={onSuccess} {...props} undoable={false}>
      <SiteUserForm toolbar={<SiteUserEditToolbar />} />
    </Edit>
  );
};
