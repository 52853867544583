import React, { Fragment, cloneElement } from "react";
import {
  ReferenceField,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BulkDeleteButton,
  BooleanInput,
  Toolbar,
  TopToolbar,
  CreateButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  CloneButton,
  required,
  EmailField,
  NumberField,
  DateField,
  ReferenceManyField,
  useRedirect,
  useNotify,
  useRefresh,
  TabbedShowLayout,
  Tab,
  CardActions,
  ListButton,
  RefreshButton,
  useRecordContext,
  Link
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import QRCode from "react-qr-code";
import myAxios from "helpers/axios";
import RemoteSelectInput from "components/RemoteSelectInput";
import BooleanNumField from "components/BooleanNumField";
import Config from "Config";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const AddNewSiteWhatsappButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/admin/site-whatsapps/create",
        // Here we specify the initial record for the create view
        state: { record: { site_id: record?.id } },
      }}
      label="加入Whatsapp"
    >
      <WhatsAppIcon />
    </Button>
  )
};

const SiteListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const SiteBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const SiteEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const SiteForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      source="name"
      label="分店名稱"
      validate={required()}
      fullWidth
    />
    <RemoteAutocompleteInput
      source="company_id"
      label="公司"
      remoteurl="/value/companies"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      disabled
      fullWidth
    />
    <TextInput
      source="address"
      label="分店地址"
      validate={required()}
      fullWidth
    />
    <RemoteSelectInput
      remoteurl="/value/district-code"
      source="district_code"
      idproperty="code"
      valueproperty="name"
      validate={required()}
      label="地區"
      />
    <TextInput
      source="lat"
      label="Lat"
      fullWidth
    />
    <TextInput
      source="lng"
      label="Lng"
      fullWidth
    />
    <TextInput
      source="contact"
      label="聯絡方法"
      fullWidth
    />
    <TextInput
      source="business_hour"
      label="營業時間"
      fullWidth
    />
    <BooleanInput
      source="active"
      label="Active"
      fullWidth
    />
  </SimpleForm>
)

export const SiteList = (props) => (
  <List
    {...props}
    actions={<SiteListActions />}
    bulkActionButtons={<SiteBulkActionButtons />}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="company_id" reference="admin/companies" label="所屬公司">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="active" />
      <DateField source="created_at" label="創建日期" />
      <DateField source="updated_at" label="更新日期" />
      <ShowButton label="詳情" />
      <EditButton label="編輯" />
      <CloneButton label="複製" />
    </Datagrid>
  </List>
);

const SiteShowActions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <AddNewSiteWhatsappButton record={data}/>
  </CardActions>
);

const SiteShowAside = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const generateArriveAction = React.useCallback(async () => {
    await myAxios.post(myAxios.addApiUrl(`/admin/site/generate-arrive-token?siteId=${record.id}`));
    refresh();
    notify('已生成新"到達店鋪"QR Code');

  }, []);
  const generateCompleteAction = React.useCallback(async () => {
    await myAxios.post(myAxios.addApiUrl(`/admin/site/generate-complete-token?siteId=${record.id}`));
    refresh();
    notify('已生成新"完成工程"QR Code');
  }, []);
  const generateConfirmAction = React.useCallback(async () => {
    await myAxios.post(myAxios.addApiUrl(`/admin/site/generate-confirm-token?siteId=${record.id}`));
    refresh();
    notify('已生成新"確認工程項目"QR Code');
  }, []);
  return (
    <div style={{ width: 'auto', margin: '1em', marginTop: 0 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>到達店鋪QR Code <span style={{fontWeight: 700}}>[{record?.arrive_token}]</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ 
            position: 'absolute', 
            width: '250px', 
            alignItem: 'center', }}
          >
            <Button onClick={generateArriveAction} variant="contained">
              生成新QR Code
            </Button>
          </div>
          <div style={{ background: 'white', padding: '64px' }}>
            <QRCode value={record?.arrive_token} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>確認報價單QR Code <span style={{fontWeight: 700}}>[{record?.confirm_token}]</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ position: 'absolute' }}>
            <Button onClick={generateConfirmAction} variant="contained">
              生成新QR Code
            </Button>
          </div>
          <div style={{ background: 'white', padding: '64px' }}>
            <QRCode value={record?.confirm_token} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>確認完成訂單QR Code <span style={{fontWeight: 700}}>[{record?.complete_token}]</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ position: 'absolute' }}>
            <Button onClick={generateCompleteAction} variant="contained">
              生成新QR Code
            </Button>
          </div>
          <div style={{ background: 'white', padding: '64px' }}>
            <QRCode value={record?.complete_token} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const WhatsappGroupField = (props) => {
  // console.log(props)
  const record = useRecordContext(props);
    const [result, setResult] = React.useState([]);
      React.useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token",Config.ultraMsgToken);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders, 
          redirect: 'follow'
        };

        fetch("https://api.ultramsg.com/"+Config.ultraMsgInstanceId+"/groups?" + urlencoded, requestOptions)
          .then(response => response.json())
          .then(result => setResult(result))
          .catch(error => console.log('error', error));
  }, [record?.receiver]);
  if (!result || !String(record.receiver).includes('@')) return (
    <div>
      {/* <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-64 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">{props.label}</label> */}
      <span className="RaLabeled-value-65">{record.receiver}</span>
    </div>
  )
  return (
    <div>
      {/* <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-64 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">{props.label}</label> */}
      <span className="RaLabeled-value-65">{result.find(r => r.id === record?.receiver)?.name}</span>
    </div>
  )
}

export const SiteShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show {...props} actions={<SiteShowActions />} aside={<SiteShowAside record={record} />}>
      <TabbedShowLayout>
        <Tab label="分店資料">
          <TextField source="name" label="分店名稱" />
          <ReferenceField source="company_id" reference="admin/companies" label="所屬公司">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="contact" label="聯絡方法" />
          <TextField source="business_hour" label="營業時間" />
          <TextField source="lat" label="Latitude" />
          <TextField source="lng" label="Longtitude" />
        </Tab>
        <Tab label="Whatsapp通知">
          <ReferenceManyField label={false} reference="admin/site-whatsapps" target="site_id">
            <Datagrid>
              <WhatsappGroupField source="receiver" label="Whatsapp電話/Group" />
              <TextField source="whatsappType.name" label="內容" />
              <BooleanNumField source="active" label="Active" />
              <EditButton label="更新"/>
              <DeleteButton label="刪除"/>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};

export const SiteCreate = (props) => (
  <Create {...props}>
    <SiteForm />
  </Create>
);

export const SiteEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`分店已更新`);
    redirect('/admin/companies/' + data.company_id + '/show/sites');
    refresh();
  };
  return (
    <Edit {...props} undoable={false} onSuccess={onSuccess} >
      <SiteForm toolbar={<SiteEditToolbar />} />
    </Edit>
  )
};