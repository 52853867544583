import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';

const ServiceItemSubCategoryForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" label="項目名稱" required />
      <TextInput source="name_en" label="項目名稱(英)" required />
      <TextInput source="code" label="項目系統代碼" required />
      <ReferenceInput label="主類別" source="categoryId" reference='code/service-item-categories'>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" label="Active" defaultValue={1}/>
    </SimpleForm>
  )
    ;
}

export default ServiceItemSubCategoryForm;