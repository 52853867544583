import OrderCreateForm from './OrderCreateForm';
import {
  Create, useNotify, useRefresh, useRedirect
} from 'react-admin';

const OrderCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`訂單已建立`);
    redirect('/admin/orders/' + data.id + '/show');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <OrderCreateForm />
    </Create>
  )
};

export default OrderCreate;