import RemoteSelectInput from 'components/RemoteSelectInput';
import { DateInput, NumberInput, SimpleForm, TextField, required } from 'react-admin';

const WorkerStatementCreateForm = (props) => {
  return (
    <SimpleForm {...props}>
      <RemoteSelectInput
        source="worker_id"
        label="師傅"
        remoteurl="/value/workers"
        idproperty="id"
        valueproperty="label"
        validate={required()}
        alwaysOn
        translateChoice={false}
      />
      <DateInput label="由" source="from_date" />
      <DateInput label="至" source="to_date" />
      <NumberInput source="fee" label="平台費(%)" defaultValue={10} />
    </SimpleForm>
  );
};

export default WorkerStatementCreateForm;
