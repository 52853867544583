import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    FunctionField,
    CardActions,
    ListButton,
    RefreshButton,
    useRecordContext,
    useGetOne,
    linkToRecord,
    Link,
    DateField,
    ArrayField,
    Datagrid,
    useNotify,
    useShowController,
    ReferenceField,
    ReferenceFieldController,
} from "react-admin";
import { Button } from '@material-ui/core';
import myAxios from "helpers/axios";
import NroSlipQuickCreateButton from "./NroSlipQuickCreateButton";
import NroRequestLogButton from "./NroRequestLogButton";

const NroRequestShowActions = ({ basePath, data }) => {
    return (
        <CardActions>
            <ListButton basePath={basePath} />
            <RefreshButton />
            <NroRequestLogButton requestId={data?.id} />
        </CardActions>
    )
};

const NroRequestShow = (props) => {
    const notify = useNotify();
    const { record } = useShowController(props);
    const [submitting, setSubmitting] = React.useState();
    const [downloading, setDownloading] = React.useState(false);
    const handleManuelSendEmail = React.useCallback(async () => {
        setSubmitting(true);
        myAxios.post(myAxios.addApiUrl(`/admin/non-regular-order-item-request/send-email?request_id=${record?.id}`))
            .then(resp => notify('電郵已發送'))
            .catch(error => notify('無法發送電郵'))
            .finally(() => setSubmitting(false));
    }, []);
    const downloadPdf = async() => {
        setDownloading(true)
        try {
          myAxios.get(myAxios.addApiUrl(`/file/pdf/nro?request_id=${record.id}`), {
            responseType: "blob",
          }).then((response) => {
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
             const pdfWindow = window.open();
             pdfWindow.location.href = fileURL; 
             setDownloading(false);           
          })
          .catch((error) => {
            console.log(error);
            setDownloading(false);   
          });
        } catch (error) {
            return { error };
        }
    }
    return (
        <Show actions={<NroRequestShowActions />} {...props} >
            <SimpleShowLayout>
                <ReferenceField label="訂單編號" source="order.id" reference="admin/orders">
                    <TextField source="ref" />
                </ReferenceField>
                <TextField source="status" label="Status" />
                <TextField source="order.order_cat_type" label="訂單類別" />
                <ReferenceField label="Requested By" source="requested_by" reference="admin/worker-users">
                    <TextField source="username" />
                </ReferenceField>
                <TextField source="approvedStatusChanger" label="Approved By"/> 
                <DateField source="created_at" label="建立時間" showTime />
                <DateField source="updated_at" label="最後更新時間" showTime />
                <ArrayField source="details" label="詳細資料">
                    <Datagrid>
                        <ReferenceField label="維修項目資料" reference="code/service-items" source="service_item_id">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="公司價目表價錢(HKD)" reference="admin/company-service-items" source="company_service_item_id">
                            <TextField source="charge_price" />
                        </ReferenceField>
                        <ReferenceField label="師傅價目表價錢(HKD)" reference="admin/worker-service-items" source="worker_service_item_id">
                            <TextField source="cost" />
                        </ReferenceField>
                        <TextField source="requested_qty" label="數量" />
                    </Datagrid>
                </ArrayField>
                <NroSlipQuickCreateButton record={record} />
                <Button disabled={submitting} mode="contained" style={{ backgroundColor: '#333', marginTop: 5, marginBottom: 5 }} onClick={handleManuelSendEmail}>
                    手動發送電郵至用戶
                </Button>
                <Button onClick={downloadPdf} disabled={downloading} mode="contained" style={{ backgroundColor: '#333' }}>
                    下載報價單
                </Button>
            </SimpleShowLayout>
        </Show>
    )
}

export default NroRequestShow;