import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import JobItemWorkerServiceItemRemoteSelectInput from 'components/jobItem/JobItemWorkerServiceItemRemoteSelectInput';
import React from 'react';
import { BooleanInput, ImageField, ImageInput, SimpleForm, TextInput, required } from 'react-admin';

const TagEditForm = (props) => {
  return (
    <SimpleForm {...props}>
      <RemoteSelectInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        alwaysOn
        fullWidth
      />
      <RemoteSelectInput
        source="order_cat_type"
        label="所屬訂單類別"
        remoteurl="/value/order-cat-type"
        idproperty="id"
        valueproperty="name"
        alwaysOn
        fullWidth
      />
      <SiteRemoteSelectInput
        source="site_id"
        label="分店"
        remoteurl="/value/sites"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
      />
      <TextInput source="name" label="名稱(標籤號碼)" />
      <TextInput source="description" label="描述" multiline />
      <BooleanInput source="status" label="已註冊" />
      <BooleanInput source="active" label="Active" />
    </SimpleForm>
  );
};

export default TagEditForm;
