import React, { Fragment, cloneElement } from 'react';
import {
  ReferenceField,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BulkDeleteButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  CloneButton,
  required,
  EmailField,
  NumberField,
  DateField,
  FunctionField,
  PasswordInput,
  SelectInput,
  useRedirect,
  useRefresh,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import { convertLocaleDate } from 'helpers/localeDate';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const AdminUserListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const AdminUserBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const AdminUserEditToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const AdminUserForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="username" label="用戶帳號" validate={required()} fullWidth />
    <TextInput source="full_name" label="用戶名稱" validate={required()} fullWidth />
    <PasswordInput source="password" label="密碼" fullWidth />
    <TextInput source="email" label="Email" fullWidth />
    <SelectInput
      source="status"
      label="狀態"
      choices={[
        { id: '10', name: 'Active' },
        { id: '0', name: 'Inactive' },
      ]}
    />
  </SimpleForm>
);

export const AdminUserList = (props) => (
  <List {...props} actions={<AdminUserListActions />} bulkActionButtons={<AdminUserBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="username" label="帳戶名稱" />
      <TextField source="full_name" label="使用者名稱" />
      <EmailField source="email" label="電郵" />
      <NumberField source="status" label="狀態" />
      <FunctionField
        label="創建日期"
        render={(record) => {
          const date = new Date(`${record.created_at}`);
          return <div>{convertLocaleDate(date)}</div>;
        }}
      />
      <FunctionField
        label="更新日期"
        render={(record) => {
          const date = new Date(`${record.updated_at}`);
          return <div>{convertLocaleDate(date)}</div>;
        }}
      />
      {/*<ShowButton label="詳情"/>*/}
      <EditButton label="編輯" />
      <CloneButton label="複製" />
    </Datagrid>
  </List>
);

export const AdminUserShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="username" label="用戶帳號" />
        <TextField source="fullname" label="用戶名稱" />
        <TextField source="email" label="Email" />
      </SimpleShowLayout>
    </Show>
  );
};

export const AdminUserCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`帳號已創建`);
    redirect('/admin/admin-users');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <AdminUserForm />
    </Create>
  );
};

export const AdminUserEdit = (props) => (
  <Edit {...props} undoable={false}>
    <AdminUserForm toolbar={<AdminUserEditToolbar />} />
  </Edit>
);
