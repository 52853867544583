const {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  useListContext,
  useResourceContext,
  ReferenceField,
} = require('react-admin');

const WaitingNroOrderList = () => {
  return (
    <List
      resource="admin/dashboard/waiting-nro"
      basePath=""
      sort={{ field: 'created_at', order: 'desc' }}
      pagination={false}
      perPage={99}
      exporter={false}
    >
      <Datagrid>
        <TextField source="ref" label="訂單編號" />
        <TextField source="orderCatType.name" label="類別" />
        <TextField source="pickupWorker.full_name" label="接單師傅" />
        <TextField source="company.name" label="公司" />
        <TextField source="site.name" label="分店" />
        <TextField source="created_at" label="建立時間" />
        <ShowButton basePath="admin/orders" label="詳細" />
      </Datagrid>
    </List>
  );
};

export default WaitingNroOrderList;
