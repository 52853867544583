import React, { Children } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button as MuiButton } from '@material-ui/core';

const MuiDialog = React.forwardRef((props, ref) => {
  const {
    onClose,
    handleConfirmAction,
    value: valueProp,
    open,
    title,
    confirmLabel = '確認',
    cancelLabel = '取消',
    dialogContentChildren,
    error,
    formId,
    ...other
  } = props;
  const handleCancel = () => {
    onClose();
  };

  const handleOk = (event) => {
    event.preventDefault();
    handleConfirmAction();
    // onClose();
  };

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContentChildren && dialogContentChildren}</DialogContent>
      <DialogActions>
        <MuiButton autoFocus onClick={handleCancel}>
          {cancelLabel}
        </MuiButton>
        {handleConfirmAction && (
          <MuiButton onClick={handleOk} type="button">
            {confirmLabel}
          </MuiButton>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default MuiDialog;
