import {
  Create, useNotify, useRefresh, useRedirect
} from 'react-admin';
import WorkerStatementCreateForm from './WorkerStatementCreateForm';

const WorkerStatementCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`工資單已建立`);
    redirect('/admin/worker-statements/' + data.id + '/show');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <WorkerStatementCreateForm />
    </Create>
  )
};

export default WorkerStatementCreate;