import { convertLocaleDate } from "helpers/localeDate";
import React, { Fragment, cloneElement } from "react";
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ShowButton,
    EditButton,
    CreateButton,
    BulkDeleteButton,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    DateField,
    linkToRecord,
    useRecordContext,
    useGetOne
} from 'react-admin';
import { Link } from 'react-router-dom';

const DefectOrderListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} label="新增" />
        </TopToolbar>
    );
};

const DefectOrderBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

const OrderField = (props) => {
    const post = useRecordContext(props);
    const { data, loaded } = useGetOne('admin/orders', post?.order_id);
    const orderShowPage = linkToRecord('/admin/orders', post?.order_id, 'show');
    return loaded ? <Link to={orderShowPage}>{data.ref}</Link> : null;
};

const DefectOrderList = (props) => (
    <List
        {...props}
        actions={<DefectOrderListActions />}
        bulkActionButtons={<DefectOrderBulkActionButtons />}
        sort={{ field: 'appointment_date', DefectOrder: 'DESC' }}
    >
        <Datagrid rowClick={false}>
            <OrderField source="orderRef" label="原生訂單編號" />
            <TextField source="order_cat_type" label="訂單類別" />
            <TextField source="cost" label="成本" />
            <FunctionField label="預約時間"
                render={record => {
                const date = new Date(`${record.appointment_date}T${record.appointment_time}Z`);
                const dateStr = date.toLocaleString();
                return (
                    <div>
                    {dateStr}
                    </div>
                    )

                }}
            />
            <TextField source="status" label="Status" />
            <FunctionField label="建立日期"
                render={record => {
                    const date = new Date(`${record.created_at}`);
                    return (
                        <div>
                            {convertLocaleDate(date)}
                        </div>
                    )
                }}
            />
            <FunctionField label="更新日期"
                render={record => {
                    const date = new Date(`${record.updated_at}`);
                    return (
                        <div>
                            {convertLocaleDate(date)}
                        </div>
                    )
                }}
            />
            <ShowButton label="詳情" />
            <EditButton label="編輯" />
        </Datagrid>
    </List>
);

export default DefectOrderList;