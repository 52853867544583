import { IconButton, Dialog, CircularProgress, Button as MuiButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const MuiDeleteButton = ({ onClick }) => {
  return (
    <IconButton style={{ backgroundColor: 'red' }} onClick={onClick}>
      <DeleteIcon style={{ fill: '#fff' }} />
    </IconButton>
  );
};

const MuiOpenNewButton = ({ onClick }) => {
  return (
    <IconButton style={{ backgroundColor: 'green' }} onClick={onClick}>
      <OpenInNewIcon style={{ fill: '#fff' }} />
    </IconButton>
  );
};

const MuiAddButton = ({ onClick }) => {
  return (
    <IconButton style={{ backgroundColor: 'green' }} onClick={onClick}>
      <AddIcon style={{ fill: '#fff' }} />
    </IconButton>
  );
};

export { MuiDeleteButton, MuiOpenNewButton, MuiAddButton };
