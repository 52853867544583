const { SimpleForm, TextField, TextInput } = require("react-admin")

const NroRequestDetailsForm = (props) => {
    return (
        <SimpleForm {...props}>
            <TextField source="company_service_item_name" label="維修項目名稱"/>
            <TextInput source="requested_qty" label="數量" />
            <TextInput source="company_service_item_charge_price" label="單價" />
            <p>*修改 <strong>單價</strong> 會同時更新<strong>公司價目表</strong></p>
        </SimpleForm>
    )
}

export default NroRequestDetailsForm