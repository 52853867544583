import React from 'react';
import { Link, Button, useNotify, useUpdate, DateInput } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import MuiButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import Save from '@material-ui/icons/Save';
import MuiTextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';

const TextInput = withStyles({
    root: {
        margin: '16px 0px'
    }
})(MuiTextField);

const WorkerStatementDateModal = ({record}) => {
    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
     }; 
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleSetModal = React.useCallback(() => {
        setModalOpen(!modalOpen);
    }, [modalOpen]);
    const [update] = useUpdate(); // Access dataProvider API call
    const notify = useNotify(); // Initialize notify object to send notification to dashboard
    const handleSubmit = async values => {
        await update(
            'admin/orders',
            record.id,
            { worker_statement_date: values.worker_statement_date },
            record
        );
        setModalOpen(false)
        notify('師傅工資單日期已更新');
    };
    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">更新工資單日期</DialogTitle>
                <DialogContent>
                    <Form 
                        onSubmit={handleSubmit}
                        keepDirtyOnReinitialize
                        subscription={defaultSubscription}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="worker_statement_date" defaultValue={record.worker_statement_date}>
                                   {props => (
                                       <div>
                                           <DateInput
                                               label={false}
                                               variant="filled"
                                               name={props.input.name}
                                               value={props.input.value}
                                               onChange={props.input.onChange}
                                           />
                                       </div>
                                   )}
                               </Field>
                               <MuiButton variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                                   <Save style={{ marginRight: '8px' }} />確認
                               </MuiButton>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
            <Button
                component={Link}
                onClick={() => handleSetModal()}
                label="更新工資單日期"
            >
                <ReceiptIcon />
            </Button>
        </>
    )
}

export default WorkerStatementDateModal