import React from 'react';
import { useNotify, Create, SimpleForm, ImageField, ImageInput, useRefresh } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { MuiAddButton } from 'components/button/buttons';

const TagPicCreateModal = ({ record }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleSetModal = React.useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);
  const notify = useNotify(); // Initialize notify object to send notification to dashboard
  const refresh = useRefresh();
  const onSuccess = () => {
    setModalOpen(false);
    refresh();
    notify('相片已上載');
  };
  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">取消訂單</DialogTitle>
        <DialogContent>
          <Create
            resource="admin/tag-pics"
            basePath="admin/tag-pics"
            record={{ tag_id: record?.id }}
            onSuccess={onSuccess}
          >
            <SimpleForm>
              <ImageInput source="pic" label={'相片'} multiple={false}>
                <ImageField source="src" label={'相片'} />
              </ImageInput>
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
      <MuiAddButton onClick={() => handleSetModal()} />
    </>
  );
};

export default TagPicCreateModal;
