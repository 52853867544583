import {
  AutocompleteArrayInput,
  BooleanInput,
  Button,
  SelectInput,
  TextInput,
  required,
  useNotify,
  useRefresh,
} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  CircularProgress,
} from '@material-ui/core';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import useValue from 'hooks/useValue';
import myAxios from 'helpers/axios';
import { stringify } from 'query-string';

const BatchExportButton = () => {
  const [open, setOpen] = useState(false);
  const [sub, setSubmitting] = useState(false);
  const notify = useNotify();
  const {
    res: { data },
  } = useValue('order-cat-type');
  const onSubmit = async (values) => {
    setSubmitting(true);
    await myAxios
      .get(myAxios.addApiUrl(`/file/label/batch-tag-label?${stringify(values)}`), {
        responseType: 'blob',
      })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open('about:blank', '_blank');
        //Open the URL on new Window
        pdfWindow.location.href = fileURL;
      })
      .catch((e) => console.error(e));
    setSubmitting(false);
    notify('已下載標籤');
  };

  const onExportIndexPage = async (values) => {
    setSubmitting(true);
    await myAxios
      .get(myAxios.addApiUrl(`/file/label/tag-index-page?${stringify(values)}`), {
        responseType: 'blob',
      })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open('about:blank', '_blank');
        //Open the URL on new Window
        pdfWindow.location.href = fileURL;
      })
      .catch((e) => console.error(e))
      .finally(() => setSubmitting(false));
    notify('已下載標籤封面');
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} label="下載標籤">
        <GetAppIcon />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>下載標籤</DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <Form
            defaultValue={{
              index: false,
            }}
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit} id="form">
                  <Field name="company_id">
                    {(props) => (
                      <RemoteSelectInput
                        source={props.input.name}
                        label="公司"
                        remoteurl="/value/companies"
                        idproperty="id"
                        valueproperty="name"
                        alwaysOn
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="site_id">
                    {(props) => (
                      <SiteRemoteSelectInput
                        source={props.input.name}
                        label="分店"
                        remoteurl="/value/sites"
                        idproperty="id"
                        valueproperty="name"
                        alwaysOn
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="all_order_cat_type">
                    {(props) => <BooleanInput label="全部維修類別" source={props.input.name} defaultValue={1} />}
                  </Field>
                  {values.all_order_cat_type == true ? null : (
                    <Field name="order_cat_type">
                      {(props) => (
                        <AutocompleteArrayInput
                          source={props.input.name}
                          choices={data.map((item) => ({ id: item.id, name: item.name }))}
                          label="維修類別"
                          fullWidth
                          validate={required()}
                        />
                      )}
                    </Field>
                  )}

                  <Field name="double_label">
                    {(props) => <BooleanInput label="下載後備標籤" source={props.input.name} defaultValue={0} />}
                  </Field>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
                    <MuiButton onClick={() => setOpen(false)} color="primary">
                      取消
                    </MuiButton>
                    {values.site_id != null && (
                      <MuiButton
                        startIcon={sub ? <CircularProgress /> : null}
                        disabled={sub}
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={() => onExportIndexPage(values)}
                      >
                        下載分店標籤封面
                      </MuiButton>
                    )}
                    <MuiButton
                      startIcon={submitting ? <CircularProgress /> : null}
                      disabled={submitting}
                      type="submit"
                      form="form"
                      color="primary"
                      variant="contained"
                    >
                      下載標籤
                    </MuiButton>
                  </div>
                </form>
              );
            }}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default BatchExportButton;
