import {
  AutocompleteArrayInput,
  BooleanInput,
  Button,
  SelectInput,
  TextInput,
  required,
  useNotify,
  useRefresh,
} from 'react-admin';
import PostAddIcon from '@material-ui/icons/PostAdd';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button as MuiButton,
  Typography,
  FormControl,
  Input,
  Label,
  InputLabel,
  Select,
} from '@material-ui/core';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Save from '@material-ui/icons/Save';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import useValue from 'hooks/useValue';
import { forIn } from 'lodash';
import myAxios from 'helpers/axios';

const BatchCreateButton = () => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const {
    res: { data },
  } = useValue('order-cat-type');
  const handleSubmit = async (values) => {
    setSubmitting(true);
    console.log(values);
    await myAxios.post(myAxios.addApiUrl(`/admin/tag/batch-create`), values).catch((e) => console.error(e));
    setSubmitting(false);
    setOpen(false);
    refresh();
    notify('成功建立標籤');
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} label="批量生成標籤">
        <PostAddIcon />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>批量生成標籤</DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <Form
            mutators={{
              clearTotalQuantity: (args, state, utils) => {
                utils.changeValue(state, 'total_quantity', () => null);
              },
            }}
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit} id="form">
                  <Field name="company_id">
                    {(props) => (
                      <RemoteSelectInput
                        source={props.input.name}
                        label="公司"
                        remoteurl="/value/companies"
                        idproperty="id"
                        valueproperty="name"
                        alwaysOn
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="site_id">
                    {(props) => (
                      <SiteRemoteSelectInput
                        source={props.input.name}
                        label="分店"
                        remoteurl="/value/sites"
                        idproperty="id"
                        valueproperty="name"
                        validate={required()}
                        alwaysOn
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="order_cat_type">
                    {(props) => (
                      <AutocompleteArrayInput
                        source={props.input.name}
                        choices={data.map((item) => ({ id: item.id, name: item.name }))}
                        label="維修類別"
                        fullWidth
                      />
                    )}
                  </Field>
                  {values.order_cat_type && values.order_cat_type.length > 0 ? (
                    values.order_cat_type.map((value, index) => {
                      return (
                        <Field name={`order_cat_type_quantity_${value}`} key={index}>
                          {(props) => (
                            <TextInput
                              source={props.input.name}
                              label={`${data.find((r) => r.id === value).name} - 數量`}
                              fullWidth
                              defaultValue={10}
                              validate={required()}
                            />
                          )}
                        </Field>
                      );
                    })
                  ) : (
                    <Field name="total_quantity">
                      {(props) => (
                        <TextInput
                          source={props.input.name}
                          label={`總生成數量`}
                          fullWidth
                          defaultValue={100}
                          validate={required()}
                        />
                      )}
                    </Field>
                  )}
                  <Field name="force_start_from_one">
                    {(props) => {
                      return (
                        <>
                          <p>正常情況下系統會找出最大的標籤數字, 再按該數字生成新標籤</p>
                          <BooleanInput source={props.input.name} label="強制從001開始生成標籤, 重複的標籤將會跳過" />
                        </>
                      );
                    }}
                  </Field>
                </form>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setOpen(false)} color="primary">
            取消
          </MuiButton>
          <MuiButton type="submit" form="form" color="primary">
            確認
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const BatchCreateModal = ({ open, onClose }) => {};

export default BatchCreateButton;
