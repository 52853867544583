import {
  Create, useNotify, useRedirect, useRefresh
} from 'react-admin';
import ServiceItemForm from './ServiceItemForm';

const ServiceItemCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`維修項目已建立`);
    redirect('/code/service-items/');
    refresh();
  }; 
  return (
    <Create {...props} onSuccess={onSuccess}>
      <ServiceItemForm />
    </Create>
  )
};

export default ServiceItemCreate;