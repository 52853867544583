import {
    Create, useNotify, useRedirect, useRefresh
  } from 'react-admin';
  import ServiceItemCategoryForm from './serviceItemCategoryForm';
  
  const ServiceItemCategoryCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({data}) => {
      notify(`維修類別已建立`);
      redirect('/code/service-item-categories/');
      refresh();
    }; 
    return (
      <Create {...props} onSuccess={onSuccess}>
        <ServiceItemCategoryForm />
      </Create>
    )
  };
  
  export default ServiceItemCategoryCreate;