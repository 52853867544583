import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,
} from 'react-admin';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SubCategoryRemoteSelectInput from 'components/SubCategoryRemoteSelectInput';

const ServiceItemForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" label="項目名稱" required />
      <TextInput source="name_en" label="項目名稱(英)" required />
      <TextInput source="code" label="項目系統代碼 (系統自動生成)" disabled required />
      <RemoteSelectInput
        source="category_code"
        label="項目類別"
        remoteurl="/value/service-item-category"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <SubCategoryRemoteSelectInput
        source="sub_category_code"
        label="項目子類別"
        remoteurl="/value/service-item-sub-category"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <TextInput source="list_price" label="參考價格" />
      <TextInput source="cost" label="參考成本" />
      <TextInput source="description" label="項目描述" />
      <TextInput source="description_en" label="項目描述(英)" />
      <ImageInput source="serviceItemPics" label="相片" accept="image/*" 
        labelMultiple="最多請上載3張圖片 (png, jpg)"
        multiple
        fullWidth>
          <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="seq" defaultValue="1" label="排序 (系統預設)" />
      <BooleanInput source="active" label="Active" defaultValue={1}/>
    </SimpleForm>
  )
    ;
}

export default ServiceItemForm;