import RemoteAutocompleteInput from "components/RemoteAutocompleteInput";
import SubCategoryRemoteSelectInput from "components/SubCategoryRemoteSelectInput";
import React, { Fragment, cloneElement } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    BulkDeleteButton,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    Filter,
    required,
    CreateButton,
    EditButton,
    TextInput,
} from 'react-admin';
import { Link } from 'react-router-dom';

const ServiceItemListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} label="新增" />
        </TopToolbar>
    );
};

const ServiceItemBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

const ServiceItemFilter = (props) => (
    <Filter {...props}>
      <RemoteAutocompleteInput
        source="category_code"
        label="維修類別"
        remoteurl="/value/service-item-category"
        idproperty = "code"
        valueproperty = "name"
        alwaysOn
      />
      <SubCategoryRemoteSelectInput
        source="sub_category_code"
        label="項目子類別"
        remoteurl="/value/service-item-sub-category"
        idproperty="code"
        valueproperty="name"
        alwaysOn
        fullWidth
      />
      <TextInput source="keyword" label="關鍵字搜尋" />
    </Filter>
  );

const ServiceItemCategoryList = (props) => (
    <List
        {...props}
        // filters={<ServiceItemFilter />}
        actions={<ServiceItemListActions />}
        bulkActionButtons={<ServiceItemBulkActionButtons />}
        sort={{ field: 'id', order: 'ASC' }}
    >
        <Datagrid rowClick={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" label="項目名稱" />
            <TextField source="name_en" label="項目名稱(英)" />
            <ShowButton label="詳情" />
            <EditButton label="編輯" />
        </Datagrid>
    </List>
);

export default ServiceItemCategoryList;