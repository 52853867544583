import React from 'react';
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  useRecordContext,
  ReferenceField,
  TextField,
  ArrayField,
  Datagrid,
  FunctionField,
  EditButton,
  ReferenceArrayField,
  ReferenceManyField,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import myAxios from 'helpers/axios';
import ServiceItemReferenceInput from './ServiceItemReferenceInput';
import RichTextInput from 'ra-input-rich-text';

const CompanyPriceInput = props => {
  const formState = useFormState();
  const form = useForm();
  const currentIndex = props.source.match(/\d/g)[0];
  const [existingItem, setExistingItem] = React.useState(null);
  React.useEffect(() => {
    let itemId = formState?.values?.orderItems[currentIndex]?.item_id;
    let companyId = formState?.values?.order?.company_id;
    if (itemId && companyId) {
      myAxios.get(myAxios.addApiUrl(`/admin/company-service-items?item_id=${itemId}&company_id=${companyId}`)).then(resp => {
        setExistingItem(resp?.data?.items[0]);
        form.change(props.source, resp?.data?.items[0]?.cost);
      }).catch(error => console.log(error));
    }
  }, [formState?.values?.orderItems[currentIndex]?.item_id]);

  return (
    <TextInput
      disabled={existingItem?.price != null}
      {...props}
    />
  )
}

const WorkerCostInput = props => {
  const formState = useFormState();
  const form = useForm();
  const currentIndex = props.source.match(/\d/g)[0];
  const [existingItem, setExistingItem] = React.useState(null);
  React.useEffect(() => {
    let itemId = formState?.values?.orderItems[currentIndex]?.item_id;
    let workerId = formState?.values?.requested_by;
    if (itemId && workerId) {
      myAxios.get(myAxios.addApiUrl(`/admin/worker-service-items?item_id=${itemId}&worker_id=${workerId}`)).then(resp => {
        setExistingItem(resp?.data?.items[0]);
        form.change(props.source, resp?.data?.items[0]?.cost);
      }).catch(error => console.log(error));
    }
  }, [formState?.values?.orderItems[currentIndex]?.item_id]);
  return (
    <TextInput
      disabled={existingItem?.cost != null}
      {...props}
    />
  )
}

const NroRequestForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm {...props}>
      <h3>新加維修項目及自動加入價目表</h3>
      <TextInput source="id" label="ID" disabled />
      <h4>訂單類別: {record.order?.order_cat_type}</h4>
      <ReferenceField label="訂單公司" source="order.company_id" reference="admin/companies">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="requestedStatusChanger" label="負責師傅" />
      <TextField source="status" label="狀態" />
      {
        props.edit &&
        <React.Fragment>
        <ReferenceManyField reference="admin/non-regular-order-item-request-details" target='request_id'>
          <Datagrid>
            <TextField source="company_service_item_name" label="維修項目" />
            <TextField source="requested_qty" label="數量" />
            <TextField source="company_service_item_charge_price" label="單價" />
            <FunctionField render={record => record?.company_service_item_charge_price * record?.requested_qty} label="價錢" />
            <TextField source="workerServiceItemCharge" label="成本" />
            <EditButton label="修改" />
          </Datagrid>
        </ReferenceManyField>
        </React.Fragment>
      }
      <ArrayInput source="orderItems" label="加入維修項目" translateChoice={false}>
        <SimpleFormIterator>
          <ServiceItemReferenceInput
            filter={{ company_id: record?.order?.company_id, category_code: record?.order?.order_cat_type }}
            label="維修項目(未加入公司報價單)"
            source="item_id"
            reference={`code/service-items`}
            allowEmpty
            validate={required()}
            required
          />
          <TextInput source="requested_qty" label="請求數量" />
          <CompanyPriceInput
            source="company_price"
            label="公司價目表定價"
            fullWidth={false}
            validate={required()}
            required
          />
          <WorkerCostInput
            source="worker_price"
            label="師傅價目表成本"
            fullWidth={false}
            validate={required()}
            required
          />
        </SimpleFormIterator>
      </ArrayInput>
      <RichTextInput 
        source="terms_and_condition" 
        validation={{ required: true }}
      />
      <p>*報價單會透過電郵發送至用戶</p>
    </SimpleForm>
  )
    ;
}

export default NroRequestForm;