import { Loading, Error, useQuery, Button, Link, useDelete, useRefresh  } from "react-admin";
import React from "react";
import { Grid, Card, CardContent, Typography, Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead,TableRow, Paper  } from "@material-ui/core";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentIcon from '@material-ui/icons/Payment';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import EditButton from '@material-ui/icons/Edit';
import DeleteButton from '@material-ui/icons/Delete';
import classnames from 'classnames';

const AddNewTranscationButton = ({ record }) => {
    return (
    <Button
      component={Link}
      to={{
        pathname: "/admin/transactions/create",
        // Here we specify the initial record for the create view
        state: { record: { payment_id: record?.id } },
      }}
      variant="outlined"
      label="加入新交易記錄"
    >
      <LocalAtmIcon />
    </Button>
    )
  };

  const UpdateTranscationButton = ({ record }) => {
    return (
        <Button
            component={Link}
            to={{
            pathname: `/admin/transactions/${record.id}`,
            // Here we specify the initial record for the create view
            state: { record: { transactionImgSrc: record.transactionImgSrc } },
            }}
            variant="outlined"
            label="更新"
        >
            <EditButton/>
        </Button>
    )
  }

  const AddNewOrderPaymentButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: "/admin/payments/create",
        // Here we specify the initial record for the create view
        state: { record: { order_id: record?.id } },
      }}
      variant="outlined"
      label=" 加入新客戶收付款"
    >
     <PaymentIcon/>
    </Button>
  );

  const AddNewJobPaymentButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: "/admin/payments/create",
        // Here we specify the initial record for the create view
        state: { record: { job_id: record?.job_id } },
      }}
      variant="outlined"
      label=" 加入新師傅收付款"
    >
     <PaymentIcon/>
    </Button>
  );

  const UpdatePaymentButton = ({ record }) => {
    console.log(record);
    return (
    <Button
      component={Link}
      to={{
        pathname: `/admin/payments/${record.id}`,
        // Here we specify the initial record for the update view
        state: { record: { order_id: record?.orderId, job_id: record?.jobId } },
      }}
      variant="outlined"
      label=" 更新"
    >
        <EditButton/>
    </Button>
    )
  }

  const PaymentDeleteButton = ({ record }) => {
    const [deleteOne, { loading }] = useDelete();
    const refresh = useRefresh();
    const handleClick = () => {
      deleteOne('admin/payments', record.id, record);
      refresh();
    };
  
    return (
      <Button
        label="刪除"
        disabled={loading}
        onClick={handleClick}
        variant="outlined"
        className={classnames(
            'ra-delete-button'
        )}
      >
        <DeleteButton/>
      </Button>
    );
  };

  const TransactionDeleteButton = ({ record }) => {
    const [deleteOne, { loading }] = useDelete();
    const refresh = useRefresh();
    const handleClick = () => {
      deleteOne('admin/transactions', record.id, record);
      refresh();
    };
  
    return (
      <Button
        label="刪除"
        disabled={loading}
        onClick={handleClick}
        variant="outlined"
        className={classnames(
            'ra-delete-button'
        )}
      >
        <DeleteButton/>
      </Button>
    );
  };
  

const OrderPayment = ({record}) => {
    console.log(record);
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'admin/payments',
        payload: { pagination: { page: 1, perPage: 20 }, sort: { field: 'id', order: 'desc' }, filter: { order_id: record?.id, job_id: record?.job_id }}
    });

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;
    return (
        <div>
            <Box sx={{display: 'flex', marginBottom: '10px'}}>
            <AddNewOrderPaymentButton record={record} />
            <AddNewJobPaymentButton record={record} />
            </Box>
            <Grid container spacing={2} sx={{marginTop: 10}}>
            {
                data.map(payment => {
                    return (
                        <Grid item xs={12}>
                            <Card variant="outlined"> 
                                <CardContent>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: '10px 0px'}}>
                                        <UpdatePaymentButton record={payment} />
                                        <PaymentDeleteButton record={payment} />
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '10px 0px'}}>
                                    <Typography sx={{fontSize: 20}}>類型: {payment?.jobId ? '師傅' : '客戶'}{payment.paymentType.name}</Typography>
                                    <Typography>狀態: {payment.paymentStatus.name}</Typography>
                                    </Box>
                                    <Divider/>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '10px 0px'}}>
                                    <Typography>金額: {payment.amount}</Typography>
                                    <Typography>折後金額: {payment.disamount || 'N/A'}</Typography>
                                    <Typography>備註: {payment.remark || 'N/A'}</Typography>
                                    </Box>
                                    <Divider my={10}/>
                                    <Typography style={{padding: '10px 0px'}}>交易記錄: </Typography>
                                    <AddNewTranscationButton record={payment} />
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>交易方式</TableCell>
                                                <TableCell>金額</TableCell>
                                                <TableCell>折後金額</TableCell>
                                                <TableCell>付款人</TableCell>
                                                <TableCell>收款人</TableCell>
                                                <TableCell>交易日期</TableCell>
                                                <TableCell>由月結單付款</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                payment.transactions ? 
                                                payment.transactions.map(tx => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>{tx.paymentMethod.name}</TableCell>
                                                            <TableCell>{tx.amount}</TableCell>
                                                            <TableCell>{tx.dis_amount || 'N/A'}</TableCell>
                                                            <TableCell>{tx.payer.name}</TableCell>
                                                            <TableCell>{tx.payee.name}</TableCell>
                                                            <TableCell>{tx.pay_date}</TableCell>
                                                            <TableCell>{tx.is_monthly_statement == 1 ? `是` : '否'}</TableCell>
                                                            <TableCell><a href={tx.transactionImgSrc} target="_blank"><ImageSearchIcon /></a></TableCell>
                                                            <TableCell><UpdateTranscationButton record={tx}/></TableCell>
                                                            <TableCell><TransactionDeleteButton record={tx} /></TableCell>
                                                        </TableRow>
                                                    )
                                                }) : <Typography>未有交易記錄</Typography>
                                            }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })
            }
            </Grid>
        </div>
    )
}

export default OrderPayment;