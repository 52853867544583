import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import React from 'react';
import {
  SimpleForm,
  TextInput,
} from 'react-admin';

const PaymentForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="order_id" label="訂單記錄ID" disabled />
      <TextInput source="job_id" label="工程記錄ID" disabled />
      <TextInput source="amount" label="款項金額" />
      <TextInput source="disamount" label="折後金額" />
      <RemoteAutocompleteInput
        source="type"
        label="類別"
        remoteurl="/value/payment-type-code"
        idproperty="code"
        valueproperty="name"
        alwaysOn
      /> 
      <RemoteAutocompleteInput
        source="status_code"
        label="狀態"
        remoteurl="/value/payment-status-code"
        idproperty="code"
        valueproperty="name"
        alwaysOn
      /> 
      <TextInput source="remark" label="收款備註" />
    </SimpleForm>
  )
    ;
}

export default PaymentForm;