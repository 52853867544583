import React, { Fragment, cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  CreateButton,
  BulkDeleteButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  TextInput,
  ReferenceField,
  SelectInput,
  ExportButton,
  downloadCSV,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import { convertLocaleDate } from 'helpers/localeDate';
import jsonExport from 'jsonexport/dist';
import RemoteSelectInput from 'components/RemoteSelectInput';
import RemoteAutocompleteArrayInput from 'components/RemoteAutocompleteArrayInput';
import Chip from '@material-ui/core/Chip';

const OrderListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
      <ExportButton />
    </TopToolbar>
  );
};

const OrderBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const OrderFilter = (props) => {
  const currentDate = new Date();
  let yearOptions = [];
  const monthOptions = Array.from({ length: 12 }, (item, i) => {
    return {
      id: i + 1,
      name: new Date(0, i).toLocaleString('zh-HK', { month: 'long' }),
    };
  });
  for (let i = currentDate.getFullYear(); i >= '2023'; i--) {
    yearOptions.push({
      id: i,
      name: `${i}年`,
    });
  }

  return (
    <Filter {...props}>
      <RemoteAutocompleteArrayInput
        source="statuses"
        label="訂單狀態"
        remoteurl="/value/order-status-code"
        idproperty="code"
        valueproperty="name"
        alwaysOn
      />
      <RemoteAutocompleteArrayInput
        source="order_cat_types"
        label="訂單類別"
        remoteurl="/value/order-cat-type"
        idproperty="code"
        valueproperty="name"
        alwaysOn
      />
      <RemoteAutocompleteInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        alwaysOn
      />
      <SiteRemoteSelectInput
        source="site_id"
        label="分店"
        remoteurl="/value/sites"
        idproperty="id"
        valueproperty="name"
        alwaysOn
      />
      <RemoteAutocompleteInput
        source="worker_id"
        label="師傅"
        remoteurl="/value/workers"
        idproperty="id"
        valueproperty="label"
        fullWidth
        alwaysOn
        translateChoice={false}
      />
      <SelectInput source="statement_year" label="月結單年份" choices={yearOptions} alwaysOn />
      <SelectInput source="statement_month" label="月結單月份" choices={monthOptions} alwaysOn />
      <SelectInput source="create_year" label="開單年份" choices={yearOptions} alwaysOn />
      <SelectInput source="create_month" label="開單月份" choices={monthOptions} alwaysOn />
      <FormDataConsumer alwaysOn>
        {({ formData, ...rest }) => {
          return (
            <ReferenceArrayInput
              perPage={100}
              label="分店標籤"
              reference="admin/tags"
              source="tagIds"
              filter={{ site_id: formData.site_id }}
              helperText={false}
            >
              <AutocompleteArrayInput optionText={`label`} />
            </ReferenceArrayInput>
          );
        }}
      </FormDataConsumer>
      <TextInput source="keyword" label="關鍵字搜尋" alwaysOn helperText="訂單號碼, 備註信息..." />
    </Filter>
  );
};

const exporter = (orders) => {
  const ordersForExport = orders.map((order) => {
    const {
      orderRemarkImages,
      company_id,
      company,
      site_id,
      site,
      job_id,
      admin_id,
      pickupWorker,
      defect_order_id,
      appointment_date,
      appointment_time,
      allow_dispatch,
      admin,
      orderCatType,
      orderStatus,
      requestedBy,
      requested_by,
      details,
      estimate_arrive_at,
      orderPaymentStatus,
      jobPaymentStatus,
      ...orderForExport
    } = order; // omit backlinks and author
    orderForExport.company = company.name;
    orderForExport.site = site.name;
    let detailsArr = details.map((detail) => detail.siteServiceItemName);
    orderForExport.details = detailsArr.toString();
    orderForExport.order_cat_type = orderCatType.name;
    orderForExport.status = orderStatus.name;
    orderForExport.appointmentDatetime = appointment_date + ' ' + appointment_time;
    return orderForExport;
  });
  jsonExport(
    ordersForExport,
    {
      headers: [
        'id',
        'ref',
        'order_cat_type',
        'company',
        'site',
        'details',
        'total_price',
        'pickupWorkerName',
        'jobTotal',
        'status',
        'appointmentDatetime',
        'is_emergency',
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV('\ufeff' + csv, 'orders'); // download as 'posts.csv` file
    }
  );
};

const OrderList = (props) => (
  <List
    {...props}
    // actions={<OrderListActions />}
    bulkActionButtons={<OrderBulkActionButtons />}
    sort={{ field: 'ref', order: 'DESC' }}
    filters={<OrderFilter />}
    exporter={exporter}
    CreateButton
  >
    <Datagrid rowClick="show">
      <TextField source="ref" label="Ref" />
      <TextField source="orderCatType.name" label="訂單類別" />
      <TextField source="company.name" label="公司名稱" />
      <TextField source="site.name" label="分店名稱" />
      <FunctionField
        label="訂單項目"
        render={(record) => {
          return (
            <ul style={{ paddingLeft: 0 }}>
              {record.details
                ? record.details.map((item) => {
                    return <li>{item.siteServiceItemName}</li>;
                  })
                : null}
            </ul>
          );
        }}
      />
      <TextField source="total_price" label="訂單總價" />
      <ReferenceField label="師傅" reference="admin/worker-users" source="pickupWorker" link="show">
        <TextField source="full_name" />
      </ReferenceField>
      <TextField source="jobTotal" label="工資" />

      {/*<BooleanNumField source="is_emergency" label="急單"/>
                  <BooleanNumField source="allow_dispatch" label="已出公海"/>*/}
      <TextField source="orderStatus.name" label="訂單狀態" />
      <FunctionField
        label="收款狀態"
        render={(record) => {
          if (!record?.jobPayamentStatus && !record?.orderPaymentStatus) return <div>未有任何記錄</div>;
          return (
            <>
              {record?.orderPaymentStatus
                ? record?.orderPaymentStatus.map((value, key) => {
                    return <div>{`${key + 1}: ${value.label}`}</div>;
                  })
                : null}
              {record?.jobPaymentStatus
                ? record?.jobPaymentStatus.map((value, key) => {
                    return <div>{`${key + 1}: ${value.label}`}</div>;
                  })
                : null}
            </>
          );
        }}
      />
      <FunctionField
        label="預約時間"
        render={(record) => {
          const date = new Date(`${record.appointment_date}T${record.appointment_time}Z`);
          const dateStr = date.toLocaleString();
          return <div>{dateStr}</div>;
        }}
      />
      {/* <FunctionField label="建立日期"
        render={record => {
          const date = new Date(`${record.created_at}`);
          return (
            <div>
              {convertLocaleDate(date)}
            </div>
          )
        }}
      />*/}
      <FunctionField
        label="驗證狀態"
        render={(record) => {
          return (
            <div>
              {record.verified ? (
                <Chip
                  label="已驗證"
                  color="secondary"
                  sx={{
                    '.MuiChip-root': {
                      backgroundColor: 'rgb(46, 125, 50)',
                    },
                  }}
                />
              ) : (
                <Chip label="未驗證" color="primary" />
              )}
            </div>
          );
        }}
      />
      <ShowButton label="詳情" />
    </Datagrid>
  </List>
);

export default OrderList;
