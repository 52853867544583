import { Button as MuiButton } from '@material-ui/core';
import { useNotify, useCreate, ImageInput, ImageField, useRefresh } from 'react-admin';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import Save from '@material-ui/icons/Save';

const UploadOrderResultItemPicModal = ({ orderId, orderItemId, orderItemName }) => {
  const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
  };
  const refresh = useRefresh();
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleSetModal = React.useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);
  const [create, { loading, error }] = useCreate();
  const notify = useNotify();
  const handleSubmit = React.useCallback(
    async (values) => {
      await create('admin/order-item-result-pics', {
        order_id: orderId,
        order_item_id: orderItemId,
        files: values.files,
      });
      setModalOpen(false);
      notify('已上載完工相片');
      refresh();
    },
    [orderId, orderItemId, create, notify, refresh]
  );
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{'上載' + orderItemName + '完工相片'}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmit}
            keepDirtyOnReinitialize
            subscription={defaultSubscription}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <Field name="files">
                  {(props) => (
                    <div>
                      <ImageInput
                        source="files"
                        label={false}
                        accept="image/*"
                        labelMultiple="最多請上載3張圖片 (png, jpg)"
                        multiple
                        fullWidth
                      >
                        <ImageField source="src" title="title" />
                      </ImageInput>
                    </div>
                  )}
                </Field>
                <MuiButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || pristine || loading}
                >
                  <Save style={{ marginRight: '8px' }} />
                  確認
                </MuiButton>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
      <MuiButton variant="contained" color="primary" onClick={() => handleSetModal()}>
        上載相片
      </MuiButton>
    </>
  );
};

export default UploadOrderResultItemPicModal;
