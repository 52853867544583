import React, { useState, useEffect } from 'react';
import { SelectInput } from 'react-admin';
import { Select, MenuItem, OutlinedInput } from '@material-ui/core';
import axios from 'helpers/axios';
import Config from 'Config';

const RemoteSelectInput = (props) => {
  const [multipleValue, setMultipleValue] = React.useState([]);
  const [options, setOptions] = useState([]);
  const [init, setInit] = useState(false);
  const remoteUrl = props?.remoteurl;
  const remoteRawUrl = props?.remoteRawUrl;
  useEffect(() => {
    if (!init) {
      var urlencoded = new URLSearchParams();
      // Temporary handle ultraMsg
      urlencoded.append('token', Config.ultraMsgToken);
      let url = remoteRawUrl ? remoteRawUrl + '?' + urlencoded : axios.addApiUrl(remoteUrl);
      axios
        .get(url)
        .then((response) => {
          setOptions(response.data);
        })
        .then(setInit(true));
    }
  }, [init, remoteUrl, remoteRawUrl, options]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMultipleValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  return (
    <>
      {props.multiple ? (
        <Select {...props} value={multipleValue} onChange={handleChange} input={<OutlinedInput label={props.label} />}>
          {options.map((row) => {
            return (
              <MenuItem key={row[props.idproperty]} value={row[props.idproperty]}>
                {row[props.valueproperty]}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <SelectInput
          {...props}
          choices={options.map((row) => {
            return {
              ...row,
              id: row[props.idproperty],
              name: row[props.valueproperty],
            };
          })}
        />
      )}
    </>
  );
};

export default RemoteSelectInput;
