import React, { useState, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { AutocompleteInput, ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ServiceItemQuickCreateButton from './ServiceItemQuickCreateButton';

const useStyles = makeStyles({
  root: {
    display: 'block',
  },
});

const spySubscription = { values: true };

const ServiceItemReferenceInput = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const { values } = useFormState({ subscription: spySubscription });
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceInput style={{ maxWidth: '40%' }} {...props} key={version}>
        <AutocompleteInput optionText="itemNameWithPrice" />
      </ReferenceInput>
      <ServiceItemQuickCreateButton onChange={handleChange} source={props.source} />
    </div>
  );
};

export default ServiceItemReferenceInput;
