import {
    Show,
    SimpleShowLayout,
    TextField,
    FunctionField,
    CardActions,
    ListButton,
    RefreshButton,
    useRecordContext,
    useGetOne,
    linkToRecord,
    Link
} from "react-admin";

const DefectOrderShowActions = ({ basePath, data }) => {
    return (
        <CardActions>
            <ListButton basePath={basePath} />
            <RefreshButton />
        </CardActions>
    )
};

const OrderField = (props) => {
    const post = useRecordContext(props);
    const { data, loaded } = useGetOne('admin/orders', post.order_id);
    const orderShowPage = linkToRecord('admin/orders', post.order_id, 'show');
    console.log(orderShowPage);
    return loaded ? <Link to={orderShowPage}>{data.ref}</Link> : null;
};

const DefectOrderShow = (props) => {
    return (
        <Show actions={<DefectOrderShowActions />} {...props}>
            <SimpleShowLayout>
                <OrderField source="orderRef" label="訂單編號" />
                <TextField source="status" label="Status" />
                <TextField source="order_cat_type" label="訂單類別" />
                <FunctionField label="預約時間"
                    render={record => {
                        const date = new Date(`${record.appointment_date}T${record.appointment_time}Z`);
                        const dateStr = date.toLocaleString();
                        return (
                            <div>
                                {dateStr}
                            </div>
                        )
                    }}
                />
                <TextField source="cost" label="成本" />
                <TextField source="workerName" label="師傅" />
                <TextField source="remark" laebl="備註" />
            </SimpleShowLayout>
        </Show>
    )
}

export default DefectOrderShow;