import React, { useState, useEffect} from 'react';
import { SelectInput } from 'react-admin';
import axios from 'helpers/axios';
import { useFormState } from 'react-final-form';
import { stringify } from 'query-string';

const CompanyServiceItemRemoteSelectInput = (props) => {
    const [options, setOptions] = useState([]);
    const [init, setInit] = useState(false);
    const remoteurl = props.remoteurl;
    const { values, dirtyFields } = useFormState();
    /*{console.log(formPrice)
    const handlePriceContextUpdate = React.useCallback(async (value)=>{
        const id = await value.target.value;
        const valPrice = options.find(r => r.id == id)?.item?.list_price;
        console.log(valPrice);
        setFormPrice({
            ...formPrice,
            [id] : options.find(r => r.id == value.target.value)?.item?.list_price,
        })
    }, []);} */
    useEffect(() => {
        if (!init){
            axios.get(axios.addApiUrl(remoteurl))
            .then((response) => {
                setOptions(response.data)
            })
            .then(setInit(true));
        }
    },[init, remoteurl, options]);
    useEffect(()=> {
        let query = {};
        if (values.company_id) {
            query = {
                ...query,
                company_id: values.company_id,
            }
        }
        if (values.order_cat_type) {
            query = {
                ...query,
                order_cat_type: values.order_cat_type,
            }
        }
        axios.get(axios.addApiUrl(`${remoteurl}?${stringify(query)}`))
        .then((response) => {
            setOptions(response.data)
        });
        
    }, [dirtyFields.company_id, values.company_id, dirtyFields.order_cate_type, values.order_cat_type]);
    return (
        <>
        {
            values.company_id && values.order_cat_type ?
            <SelectInput {...props}
            choices = {
                options.map( row => {
                    return {
                        ...row,
                        id: row[props.idproperty],
                        name: `${row[props.valueproperty]} HKD${row['item']['list_price']}`,
                    }
                })
            }
            /> :
            <div>
                請先選擇訂單類別和公司
            </div>
        }
        </>
    )
};

export default CompanyServiceItemRemoteSelectInput;