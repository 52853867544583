import {
    Create, useNotify, useRedirect, useRefresh
  } from 'react-admin';
  import ServiceItemSubCategoryForm from './serviceItemSubCategoryForm';
  
  const ServiceItemSubCategoryCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({data}) => {
      notify(`維修子類別已建立`);
      redirect('/code/service-item-sub-categories/');
      refresh();
    }; 
    return (
      <Create {...props} onSuccess={onSuccess}>
        <ServiceItemSubCategoryForm />
      </Create>
    )
  };
  
  export default ServiceItemSubCategoryCreate;