import RemoteSelectInput from 'components/RemoteSelectInput';
import { DateInput, SimpleForm, required } from 'react-admin';

const CompanyStatementCreateForm = (props) => {
  return (
    <SimpleForm {...props}>
      <RemoteSelectInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
        translateChoice={false}
      />
      <DateInput label="由" source="from_date" />
      <DateInput label="至" source="to_date" />
      <RemoteSelectInput
        source="discount_id"
        label="月結單折扣"
        remoteurl="/value/discount-rate"
        idproperty="id"
        valueproperty="name"
        alwaysOn
        translateChoice={false}
      />
    </SimpleForm>
  );
};

export default CompanyStatementCreateForm;
