import Modal  from "@material-ui/core/Modal";
import Button  from "@material-ui/core/Button";
import Typography  from "@material-ui/core/Typography";
import Box  from "@material-ui/core/Box";
import AddIcon from '@material-ui/icons/Add';
import React from "react";
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import useValue from "hooks/useValue";
import myAxios from "helpers/axios";
import { useRefresh } from "react-admin";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ImportBatchItemButton = ({record}) => {
    const [open, setOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [categoryCode, setCategoryCode] = React.useState([]);
    const {res : {data} } = useValue('service-item-category');
    const refresh = useRefresh();
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setCategoryCode(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOnSubmit = React.useCallback(async (event) => {
      //console.log(event.target);
      event.preventDefault();
      setSubmitting(true);
      await myAxios.post(myAxios.addApiUrl(`/admin/clone-service-item-template/worker-import?workerId=${record?.id}`), {
        category_code: categoryCode
      }).catch(e => console.error(e));
      setSubmitting(false);
      setOpen(false);
      refresh();
    }, [categoryCode, record?.id, refresh]);
    return (
        <div>
        <Button variant="contained" onClick={handleOpen} startIcon={<AddIcon/>}>批量加入價目表項目</Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                加入項目
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <ul>
                  <li>重置所有維修項目及價錢</li>
                </ul>
            </Typography>
            <form onSubmit={(e) => handleOnSubmit(e)}>
              <FormControl style={{ width: 300 }}>
                <InputLabel>維修類別</InputLabel>
                <Select
                  labelId="category"
                  id="demo-multiple-name"
                  multiple
                  name="category"
                  value={categoryCode}
                  onChange={handleChange}
                  defaultValue={{category: data?.map(r=>r.code)}}
                >
                  {
                      data && data?.map(cat => {
                        return (
                          <MenuItem
                            key={cat.code}
                            value={cat.code}
                          >
                            {cat.name}
                          </MenuItem>
                        )
                      })
                  }
                </Select>
                <br/>
                <Button startIcon={submitting ? <CircularProgress /> : null} disabled={categoryCode.length<=0 || submitting} type="submit" variant="contained">加入</Button>
              </FormControl>
            </form>
            </Box>
        </Modal>
        </div>
    )
}

export default ImportBatchItemButton