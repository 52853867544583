import DefectOrderForm from './DefectOrderForm';
import {
  Create, useNotify, useRedirect, useRefresh
} from 'react-admin';

const DefectOrderCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`訂單:${data.order_id}的執漏單已建立`);
    redirect('/admin/defect-orders/'+data?.id+'/show');
    refresh();
  }; 
  return (
    <Create {...props} onSuccess={onSuccess}>
      <DefectOrderForm />
    </Create>
  )
};

export default DefectOrderCreate;