import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  email,
  ReferenceArrayInput,
  FormDataConsumer,
  AutocompleteArrayInput
} from 'react-admin';

const SiteRecipientsForm = (props) => {
  return (
    <SimpleForm {...props}>
      <RemoteSelectInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
        disabled
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData.company_id) return null;
          return (
            <ReferenceArrayInput label="分店" reference="admin/sites" source="site_ids" filter={{company_id: formData?.company_id}}>
              <AutocompleteArrayInput optionText={`name`}/>
            </ReferenceArrayInput>
          )
        }}
      </FormDataConsumer>
      <RemoteSelectInput
        source="message_type"
        label="電郵接收類型"
        remoteurl="/value/notification-message-type"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <TextInput source="email" label="電郵" validate={email()}/>
    </SimpleForm>
  )
    ;
}

export default SiteRecipientsForm;