export const DEV = process.env.NODE_ENV === 'development';
const Config =  {
  DEV,
  appName: 'Three Masons - Admin',
  publicHost: DEV ? 'http://localhost:3000' : 'https://admin.3-masons.com',
  //apiHost: DEV ? 'https://api.3-masons.com/web' : 'https://api.3-masons.com/web',
  apiHost: DEV ? 'http://localhost/three-masons-api/web' : 'https://api.3-masons.com/web',
  staticImagesHost: DEV ? 'http://localhost/resources/images' : 'https://api.3-masons.com/resources/images',
  ultraMsgToken: "1rbjkiu12is8qxf6",
  ultraMsgInstanceId: "instance67447",
};
export default Config;
