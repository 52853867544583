import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ImageInput,
  ImageField,
  SelectInput,
  useRecordContext
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SubCategoryRemoteSelectInput from 'components/SubCategoryRemoteSelectInput';

const NroSlipQuickCreateButton = props => {
  const record = useRecordContext(props.record);
  const [showDialog, setShowDialog] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [create, { loading }] = useCreate('admin/non-regular-order-item-request-slips', );
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    let formData = {
      approve_type: values.approve_type,
      request_id: values.request_id,
      files: values.files
    };
    create(
      { payload: { data: formData } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          //props.onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  const handleOnChange = (val) => {
    console.log(val);
  };
  return (
    <>
      <Button disabled={record.status == 'approved' || record.status == 'approved_add_company_service_item_only'} style={{marginBottom: 4}} variant='contained' onClick={handleClick} label="手動更新請求狀態 (需要上截客戶同意證明)">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>手動更新請求狀態</DialogTitle>

        <FormWithRedirect
          resource="admin/non-regular-order-item-request-slips"
          save={handleSubmit}
          onChange={handleOnChange}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => {
            return (
            <>
              <DialogContent>
                <TextInput source="request_id" label="請求記錄ID" defaultValue={record?.id} disabled fullWidth/>
                <SelectInput source="approve_type" label="狀態" choices={[
                  {id: 'approved', name:'批准'},
                  {id: 'approved_add_company_service_item_only', name:'批准(只加入報價單)'},
                  {id: 'rejected', name: '拒絕'},
                  {id: 'void', name: '取消'},
                ]} fullWidth required/>
                <ImageInput source="files" label="客戶證明圖片" accept="image/*" multiple required>
                  <ImageField source="src" title="title" />
                </ImageInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}}
        />
      </Dialog>
    </>
  );
}

export default NroSlipQuickCreateButton;