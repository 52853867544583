import React from "react";
import {
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CompanyStatementEditForm from "./CompanyStatementEditForm";

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CompanyStatementEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const CompanyStatementEdit = (props) => (
  <Edit {...props} undoable={false} >
    <CompanyStatementEditForm toolbar={<CompanyStatementEditToolbar />} />
  </Edit>
);

export default CompanyStatementEdit;