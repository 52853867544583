import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  DateInput,
  RadioButtonGroupInput,
  FormDataConsumer,
} from 'react-admin';

const availableTimeSlot = [
  {
    name: '00:00 至 09:00',
    id: '09:00:00'
  },
  {
    name: '9:00 至 12:00',
    id: '12:00:00'
  },
  {
    name: '12:00 至 14:00',
    id: '14:00:00'
  },
  {
    name: '14:00 至 16:00',
    id: '16:00:00'
  },
  {
    name: '16:00 至 18:00',
    id: '18:00:00'
  },
  {
    name: '18:00 至 21:00',
    id: '21:00:00'
  },
  {
    name: '21:00 至 23:59',
    id: '23:59:00'
  },
];

const DefectOrderForm = (props) => {
  let todayDate = new Date();
  let day = todayDate.getDate();
  let month = String(todayDate.getMonth() + 1).padStart(2, "0");
  let year = todayDate.getFullYear();

  return (
    <SimpleForm {...props}>
      <TextInput source="order_id" label="訂單ID" disabled />
      <TextInput source="cost" label="價錢" />
      <TextInput source="order_cat_type" label="訂單類型" disabled />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          <WorkerRemoteSelectInput
            source="worker_id"
            label="師傅"
            remoteurl="/value/workers"
            defaultOrderCatType={formData.order_cat_type}
            idproperty="id"
            valueproperty="username"
            alwaysOn
            fullWidth
          />
        }
      </FormDataConsumer>
      <DateInput
        source="appointment_date"
        label="預約日期"
        InputProps={{ inputProps: { min: `${year}-${month}-${day}` } }}
      />
      <RadioButtonGroupInput
        source="appointment_time"
        label="可供預約時段"
        choices={availableTimeSlot}
        fullWidth />
      <TextInput source="remark" label="備註" />
    </SimpleForm>
  )
    ;
}

export default DefectOrderForm;