import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import React from 'react';
import {
  BooleanInput,
    DateInput,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const TransactionEditForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="payment_id" label="收款記錄ID" disabled validate={required()} />
      <TextInput source="amount" label="款項金額" validate={required()}/>
      <TextInput source="dis_amount" label="折後金額"/>
      <DateInput source="pay_date" label="收款日期" validate={required()}/>
      <RemoteAutocompleteInput
        source="payment_method_code"
        label="收付款方式"
        remoteurl="/value/payment-method-code"
        idproperty="code"
        valueproperty="name"
        alwaysOn
        validate={required()}
      />
      <RemoteAutocompleteInput
        source="payer_code"
        label="付款人"
        remoteurl="/value/payer-code"
        idproperty="code"
        valueproperty="name"
        alwaysOn
        validate={required()}
      />
      <RemoteAutocompleteInput
        source="payee_code"
        label="收款人"
        remoteurl="/value/payee-code"
        idproperty="code"
        valueproperty="name"
        alwaysOn
        validate={required()}
      />
      <BooleanInput source="is_monthly_statement" label="由月結單付款" />
      <TextInput source="remark" label="收款備註" />
      <ImageField source="transactionImgSrc" label="已上載相片" />
      <ImageInput
        source="transactionImage"
        label="收款證明相片"
        accept="image/*"
        fullWidth
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  )
    ;
}

export default TransactionEditForm;