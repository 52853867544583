import React from "react";
import {
  Edit,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin';
import OrderItemEditForm from './OrderItemEditForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const OrderItemEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const OrderItemEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({data}) => {
    notify(`訂單:${data.order_id}的項目已更新`);
    redirect('/admin/orders/'+data?.order_id+'/show/items');
    refresh();
  }; 
  return (
  <Edit {...props} undoable={false} onSuccess={onSuccess}>
    <OrderItemEditForm toolbar={<OrderItemEditToolbar />} />
  </Edit>
  )
}

export default OrderItemEdit;