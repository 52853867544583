import BooleanNumField from 'components/BooleanNumField';
import {
  BooleanField,
  CardActions,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  RefreshButton,
  Show,
  SimpleList,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useCreate,
  useGetMany,
  useShowController,
} from 'react-admin';
import {
  Paper,
  Typography,
  Button as MuiButton,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  CardActions as MuiCardActions,
} from '@material-ui/core';
import React from 'react';
import ConfirmDeleteButton from 'components/button/ConfirmDeleteButton';
import { MuiAddButton, MuiOpenNewButton } from 'components/button/buttons';
import DialogCreateForm from 'components/button/DialogCreateForm';
import TagPicCreateModal from './TagPicCreateModal';
import QRCode from 'react-qr-code';

const TagShowActions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
    <RefreshButton />
  </CardActions>
);

const RelatedOrderAside = ({ record }) => {
  const {
    data: orders,
    loading: orderLoading,
    loaded: orderLoaded,
  } = useGetMany('admin/orders', record?.orderIds || []);
  const {
    data: pics,
    loading: picLoading,
    loaded: picLoaded,
  } = useGetMany('admin/tag-pics', record?.tagImageIds || []);
  console.log(orders);
  return (
    <Paper style={{ width: 400, padding: 15 }}>
      <Typography variant="h6" gutterBottom>
        裝置相片
        {pics.length > 0 && !picLoading && picLoaded ? (
          pics.map((pic) => {
            return (
              <div style={{ position: 'relative' }}>
                <div style={{ zIndex: 9, position: 'absolute', right: 5, top: 5 }}>
                  <MuiOpenNewButton onClick={() => window.open(pic.src, '_blank', 'noopener, noreferrer')} />
                  <ConfirmDeleteButton resource={'admin/tag-pics'} id={pic?.id} dialogTitleText={'確認刪除相片?'}>
                    <img src={pic?.src} alt="" style={{ width: '100%', maxHeight: 200, objectFit: 'cover' }} />
                  </ConfirmDeleteButton>
                </div>
                <img src={pic?.src} alt="" style={{ width: '100%', maxHeight: 200, objectFit: 'cover' }} />
              </div>
            );
          })
        ) : (
          <TagPicCreateModal record={record} />
        )}
      </Typography>
      <Typography variant="h6" gutterBottom>
        下單QR code
      </Typography>
      <div style={{ background: 'white', padding: '64px' }}>
        {record?.webRequestUrl ? <QRCode value={record?.webRequestUrl} /> : null}
      </div>
      <Typography variant="h6" gutterBottom>
        相關訂單
      </Typography>
      {orderLoading && !orderLoaded ? (
        <CircularProgress />
      ) : (
        orders.length > 0 &&
        orders.map((order) => {
          return (
            <Card>
              {order?.orderRemarkImages.length ? (
                <CardMedia
                  style={{ height: 140 }}
                  image={order?.orderRemarkImages[0]?.staticServePath}
                  title="Remark Image"
                />
              ) : null}

              <CardContent>
                <Typography>
                  {order?.ref} - {order?.appointment_date}
                </Typography>
                <Typography>
                  {order?.remark} - [{order.status}]
                </Typography>
              </CardContent>
              <MuiCardActions>
                <MuiButton
                  variant="contained"
                  onClick={() => window.open(`#/admin/orders/${order?.id}/show`, '_blank', 'noopener, noreferrer')}
                >
                  查看訂單
                </MuiButton>
              </MuiCardActions>
            </Card>
          );
        })
      )}
    </Paper>
  );
};

const TagShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show actions={<TagShowActions />} {...props} aside={<RelatedOrderAside record={record} />}>
      <SimpleShowLayout>
        <ReferenceField source="site_id" reference="admin/sites" label="分店" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="orderCatTypeLabel" label="維修類別" />
        <FunctionField
          label="裝置編號"
          render={(record) => {
            return `${String(record.site_id).padStart(3, '0')}${String(record.name).padStart(3, '0')}`;
          }}
        />
        <TextField source="name" label="標籤名稱" />
        <TextField source="description" label="裝置描述" />
        <BooleanNumField source="status" label="已註冊" />
        <BooleanNumField source="active" label="Active" />
      </SimpleShowLayout>
    </Show>
  );
};

export default TagShow;
