import {
    Create, useNotify, useRefresh, useRedirect
  } from 'react-admin';
import PaymentForm from './PaymentForm';
  
  const PaymentCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
      notify(`收付款已建立`);
      redirect('/admin/orders/' + data.orderId + '/show/payments');
      refresh();
    };
    return (
      <Create {...props} onSuccess={onSuccess}>
        <PaymentForm />
      </Create>
    )
  };
  
  export default PaymentCreate;