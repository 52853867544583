import { Card } from '@material-ui/core';
import { EditButton, ReferenceManyField } from 'react-admin';

const { SimpleShowLayout, ImageField, TextField, FunctionField, ArrayField, Datagrid, DateField, Show, CardActions, ListButton, RefreshButton } = require("react-admin")


const JobShowActions = ({ basePath, data }) => {
    return (
        <CardActions>
            <ListButton basePath={basePath} />
            <RefreshButton />
        </CardActions>
    )
};

const JobShow = (props) => {
    return (
        <Show actions={<JobShowActions />} {...props}>
            <SimpleShowLayout>
                <EditButton />
                <Card variant="outlined" style={{ maxWidth: '500px', display: 'flex', border: '1px solid', padding: '5px' }}>
                    <div style={{ flexBasis: '50%' }}>
                        <ImageField source="workerProfilePic.data" src="data" />
                    </div>
                    <div style={{ flexBasis: '50%', minWidth: '100px', display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: '6px', color: '#ccc' }}>師傅編號</p>
                        <TextField label="師傅編號" source="workerProfile.wid" />
                        <p style={{ fontSize: '6px', color: '#ccc' }}>聯絡電話</p>
                        <FunctionField label="姓名" render={record => `${record.workerProfile.first_name} ${record.workerProfile.last_name}`} />
                        <p style={{ fontSize: '6px', color: '#ccc' }}>性別</p>
                        <FunctionField label="姓別" render={record => record.workerProfile.sex == 'm' ? '男' : '女'} />
                        <p style={{ fontSize: '6px', color: '#ccc' }}>聯絡電話</p>
                        <TextField source="workerProfile.contact_number" label="聯絡電話" />
                    </div>
                </Card>
                <p>工程詳細項目</p>
                <ReferenceManyField label="" reference="admin/worker-job-items" target="job_id">
                    <Datagrid>
                        <TextField label="項目名稱" source="workerServiceItemName" />
                        <TextField label="數量" source="qty" />
                        <TextField label="Unit Cost" source="price" />
                        <TextField label="Status" source="statusName" />
                        <DateField showTime={true} label="建立時間" source="created_at" />
                        <EditButton label="更新"/>
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
}

export default JobShow;