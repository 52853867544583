import BooleanNumField from 'components/BooleanNumField';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import React, { Fragment, cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  BulkDeleteButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ReferenceField,
  CreateButton,
  EditButton,
  TextInput,
  Filter,
} from 'react-admin';
import BatchCreateButton from './BatchCreateButton';
import BatchExportButton from './BatchExportButton';

const TagListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, basePath } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} label="新增" />
      <BatchCreateButton />
      <BatchExportButton />
    </TopToolbar>
  );
};

const TagBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const TagFilter = (props) => {
  return (
    <Filter {...props}>
      <RemoteAutocompleteInput
        source="company_id"
        label="公司"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        alwaysOn
      />
      <SiteRemoteSelectInput
        source="site_id"
        label="分店"
        remoteurl="/value/sites"
        idproperty="id"
        valueproperty="name"
        alwaysOn
      />
      <RemoteAutocompleteInput
        source="order_cat_type"
        label="維修類別"
        remoteurl="/value/order-cat-type"
        idproperty="id"
        valueproperty="name"
        alwaysOn
        fullWidth
      />
      <TextInput source="keyword" label="關鍵字搜尋" alwaysOn />
    </Filter>
  );
};

const TagList = (props) => {
  return (
    <List
      {...props}
      actions={<TagListActions />}
      bulkActionButtons={<TagBulkActionButtons />}
      sort={{ field: 'created_at', Job: 'ASC' }}
      filters={<TagFilter />}
    >
      <Datagrid rowClick={false}>
        <TextField label="名稱" source="name" />
        <TextField label="描述" source="description" />
        <ReferenceField label="分店" source="site_id" reference="admin/sites" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="所屬訂單類別"
          source="order_cat_type"
          reference="code/service-item-categories"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField label="已註冊" render={(record) => (record.status ? '是' : '否')} />
        <BooleanNumField source="active" label="Active" />
        <ShowButton label="詳情" />
        <EditButton label="更新" />
      </Datagrid>
    </List>
  );
};

export default TagList;
