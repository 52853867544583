import React from 'react';
import { Button, DeleteButton, useDelete, useNotify, useRedirect, useRefresh } from 'react-admin';
import ActionDelete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';

const CustomOrderItemDelete = (props) => {
  const { record, resource } = props;
  const refresh = useRefresh();

  const notify = useNotify();
  const [deleteJobItemOne, { loading: deleteJobItemOneLoading }] = useDelete(
    'admin/worker-job-items',
    record.jobItemId
  );
  const [deleteOrderItemOne, { loading: deleteOrderItemOneLoading }] = useDelete(resource, record.id, record);
  const onDelete = React.useCallback(() => {
    deleteJobItemOne();
    deleteOrderItemOne();
    notify('訂單及工程項目已刪除');
    refresh();
  }, [deleteJobItemOne, deleteOrderItemOne, notify, refresh]);

  return (
    <Button
      disabled={deleteJobItemOneLoading || deleteOrderItemOneLoading}
      onClick={onDelete}
      label="一併刪除工程項目"
      className={classnames('ra-delete-button', 'RaDeleteWithUndoButton')}
      style={{ color: '#f44336' }}
    >
      <ActionDelete />
    </Button>
  );
};

export default CustomOrderItemDelete;
