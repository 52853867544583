import React from "react";
import { Confirm, useDelete, useRefresh } from "react-admin"
import DeleteIcon from '@material-ui/icons/Delete';

const DeleteOrderResultItemPicButton = ({
    id,
    itemName
}) => {
    console.log(id);
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const [deleteOne, {isLoading, error}] = useDelete();
    const handleDelete = React.useCallback(async() => {
        await deleteOne(
            "admin/order-item-result-pics",
            id
        );
        setOpen(false);
        refresh();
    }, [deleteOne, id, refresh]);
    if (error) return <p>Error: {error.message}</p>
    return (
        <>
        <button style={{position:'absolute', left: 0, top: 0, background: 'transparent', border: 'none', cursor: 'pointer', padding: 0}} disabled={isLoading} onClick={() => setOpen(true)}>
            <DeleteIcon style={{color: 'red'}}/>
        </button>
        <Confirm
            isOpen={open}
            loading={isLoading}
            title={`刪除${itemName}的完工相片`}
            content="確定刪除?"
            cancel="取消"
            confirm="確定"
            onConfirm={handleDelete}
            onClose={() => setOpen(false)}
        />
        </>
    )
}

export default DeleteOrderResultItemPicButton