import React from 'react';
import { Link, TextField, Button, useCreate, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import MuiButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
import Save from '@material-ui/icons/Save';
import MuiTextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const TextInput = withStyles({
    root: {
        margin: '16px 0px'
    }
})(MuiTextField);

const VoidOrderModal = ({record}) => {
    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
     }; 
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleSetModal = React.useCallback(() => {
        setModalOpen(!modalOpen);
    }, []);
    const [create, { loading }] = useCreate('admin/void-orders'); // Access dataProvider API call
    const notify = useNotify(); // Initialize notify object to send notification to dashboard
    const handleSubmit = async values => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setModalOpen(false);
                    notify('ra.notification.created', 'info', { smart_count: 1 }); // Default onSuccess function
                },
                onFailure: ({ error }) => {
                    notify('Something went wrong.', 'error');
                }
            }
        );
    };
    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">取消訂單</DialogTitle>
                <DialogContent>
                    <Form 
                        onSubmit={handleSubmit}
                        keepDirtyOnReinitialize
                        subscription={defaultSubscription}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="order_id" defaultValue={record.id}>
                                   {props => (
                                       <div>
                                           <TextInput
                                               label={"訂單ID"}
                                               variant="filled"
                                               name={props.input.name}
                                               value={props.input.value}
                                               onChange={props.input.onChange}
                                               disabled
                                           />
                                       </div>
                                   )}
                               </Field>
                               <Field name="remark">
                                   {props => (
                                       <div>
                                           <TextInput
                                               label={"取消原因"}
                                               variant="filled"
                                               name={props.input.name}
                                               value={props.input.value}
                                               onChange={props.input.onChange}
                                           />
                                       </div>
                                   )}
                               </Field>
                               <MuiButton variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                                   <Save style={{ marginRight: '8px' }} />確認
                               </MuiButton>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
            <Button
                component={Link}
                onClick={() => handleSetModal()}
                label="取消訂單"
            >
                <CancelIcon />
            </Button>
        </>
    )
}

export default VoidOrderModal