import React, { Fragment, cloneElement } from "react";
import {
  ReferenceField,
  List,
  useListContext,
  useShowController,
  sanitizeListRestProps,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  Edit,
  SimpleForm,
  NumberInput,
  BulkDeleteButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  EditButton,
  CloneButton,
  required,
  NumberField,
  DateField,
  ReferenceManyField,
  BooleanInput,
  useRedirect,
  useNotify,
  useRefresh
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteAutocompleteInput from 'components/RemoteAutocompleteInput';
import RemoteSelectInput from 'components/RemoteSelectInput';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CompanyServiceItemListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增" />
    </TopToolbar>
  );
};

const CompanyServiceItemBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

const CompanyServiceItemEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const CompanyServiceItemForm = (props) => (
  <SimpleForm {...props}>
    <RemoteSelectInput
      source="company_id"
      label="公司"
      remoteurl="/value/companies"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      alwaysOn
    />
    <NumberInput
      source="charge_price"
      label="收費"
      validate={required()}
      fullWidth
    />
    <RemoteSelectInput
      source="item_id"
      label="維修項目"
      remoteurl="/value/service-items"
      idproperty="id"
      valueproperty="nameWithCat"
      validate={required()}
      alwaysOn
    />
    <RemoteSelectInput
      source="type"
      label="維修項目類別"
      remoteurl="/value/company-service-item-type"
      defaultValue="1"
      idproperty="id"
      valueproperty="name"
      validate={required()}
      alwaysOn
    />
    <BooleanInput
      source="active"
      label="Active"
    />
  </SimpleForm>
)

export const CompanyServiceItemList = (props) => (
  <List
    {...props}
    actions={<CompanyServiceItemListActions />}
    bulkActionButtons={<CompanyServiceItemBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <ReferenceField source="company_id" reference="admin/companies" label="公司">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="charge_price" label="收費" />
      <ReferenceField source="item_id" reference="code/service-items" label="維修項目">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" label="項目類別" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ShowButton label="詳情" />
      <EditButton label="編輯" />
      <CloneButton label="複製" />
    </Datagrid>
  </List>
);

export const CompanyServiceItemShow = (props) => {
  const { record } = useShowController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="公司名稱" />
        <TextField source="br" label="商業登記號碼" />
        <TextField source="contact_number" label="聯絡電話" />
        <TextField source="billing_CompanyServiceItem_name" label="帳單公司名稱" />
        <TextField source="billing_address" label="帳單地址" />
        <TextField source="billing_contact_person" label="帳單聯絡人" />
        <TextField source="billing_contact_department" label="帳單負責部門" />
        <ReferenceManyField label="分店" reference="admin/sites" target="CompanyServiceItem_id">
          <Datagrid>
            <TextField source="name" label="分店名稱" />
            <TextField source="address" label="分店地址" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
};

export const CompanyServiceItemCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`項目已建立`);
    redirect('/admin/companies/' + data.company_id + '/show/service-items');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <CompanyServiceItemForm />
    </Create>
  )
}

export const CompanyServiceItemEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`項目已更新`);
    redirect('/admin/companies/' + data.company_id + '/show/service-items');
    refresh();
  };
  return (
    <Edit {...props} undoable={false} onSuccess={onSuccess}>
      <CompanyServiceItemForm toolbar={<CompanyServiceItemEditToolbar />} />
    </Edit>
  )
};