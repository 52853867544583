import MuiButton from '@material-ui/core/Button';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import myAxios from 'helpers/axios';
import React from 'react';
import { Link, Button, useNotify, useRefresh } from 'react-admin';

const VerifyButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onClickHandler = React.useCallback(() => {
    myAxios
      .post(myAxios.addApiUrl(`/admin/order/verify?order_id=${record.id}`))
      .then((response) => {
        notify(`訂單已更新`);
        refresh();
      })
      .catch((e) => console.error(e));
  }, [record, notify, refresh]);
  return (
    <Button
      onClick={onClickHandler}
      label={record?.verified ? '取消驗證' : '驗證'}
      variant="outlined"
      color={record?.verified ? 'primary' : 'secondary'}
      //   style={{ color: 'white' }}
      component={Link}
    >
      <VerifiedIcon />
    </Button>
  );
};

export default VerifyButton;
