import React from 'react';
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  required,
  SimpleFormIterator,
  BooleanInput,
  ImageInput,
  ImageField,
  NumberInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import RemoteSelectInput from 'components/RemoteSelectInput';
import SiteRemoteSelectInput from 'components/SiteRemoteSelectInput';
import WorkerRemoteSelectInput from 'components/orderItem/WorkerRemoteSelectInput';
import CompanyServiceItemRemoteSelectInput from 'components/orderItem/CompanyServiceItemRemoteSelectInput';

const availableTimeSlot = [
  {
    name: '00:00 至 09:00',
    id: '09:00:00',
  },
  {
    name: '9:00 至 12:00',
    id: '12:00:00',
  },
  {
    name: '12:00 至 14:00',
    id: '14:00:00',
  },
  {
    name: '14:00 至 16:00',
    id: '16:00:00',
  },
  {
    name: '16:00 至 18:00',
    id: '18:00:00',
  },
  {
    name: '18:00 至 21:00',
    id: '21:00:00',
  },
  {
    name: '21:00 至 23:59',
    id: '23:59:00',
  },
];

const OrderCreateForm = (props) => {
  return (
    <SimpleForm {...props}>
      <h3>訂單基本資料</h3>
      <RemoteSelectInput
        source="order_cat_type"
        label="訂單類別 - (選擇的訂單類別會影響可選擇的師傅及工程項目價目表)"
        remoteurl="/value/order-cat-type"
        idproperty="code"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <RemoteSelectInput
        source="company_id"
        label="訂單所屬公司 - (選擇的公司會影響工程項目價目表)"
        remoteurl="/value/companies"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
        fullWidth
      />
      <SiteRemoteSelectInput
        source="site_id"
        label="訂單所屬分店"
        remoteurl="/value/sites"
        idproperty="id"
        valueproperty="name"
        validate={required()}
        alwaysOn
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData.site_id) return null;
          return (
            <ReferenceArrayInput
              label="分店標籤"
              reference="admin/tags"
              source="tag_ids"
              filter={{ site_id: formData?.site_id, order_cat_type_code: formData?.order_cat_type }}
            >
              <AutocompleteArrayInput optionText={`label`} />
            </ReferenceArrayInput>
          );
        }}
      </FormDataConsumer>
      <RadioButtonGroupInput
        source="appoint_type"
        label="訂單預約到達"
        initialValue={'asap'}
        choices={[
          { id: 'asap', name: 'ASAP (師傅會於訂單生效時間最遲4小時內到達分店)' },
          { id: 'appointment', name: '預約 (可供預約時段會根據系統提供)' },
        ]}
        required
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          let todayDate = new Date();
          let day = todayDate.getDate();
          let month = String(todayDate.getMonth() + 1).padStart(2, '0');
          let year = todayDate.getFullYear();
          let hour = String(todayDate.getHours()).padStart(2, '0');
          let hourAddFour = String(todayDate.getHours() + 4).padStart(2, '0');
          let min = String(todayDate.getMinutes()).padStart(2, '0');
          let second = String(todayDate.getSeconds()).padStart(2, '0');
          const todayTimeslotOptionRender = availableTimeSlot.filter((r) => {
            const optionDate = new Date(`${year}-${month}-${day} ${r.id}`);
            const currentDate = new Date(`${year}-${month}-${day} ${hourAddFour}:${min}:${second}`);
            return optionDate > currentDate;
          });
          return (
            <>
              {formData.appoint_type === 'appointment' ? (
                <>
                  <DateInput
                    source="appointment_date"
                    label="預約日期"
                    InputProps={{ inputProps: { min: `${year}-${month}-${day}` } }}
                    {...rest}
                  />
                  {formData.appointment_date !== undefined ? (
                    <RadioButtonGroupInput
                      source="appointment_time"
                      label="可供預約時段"
                      choices={
                        formData.appointment_date == `${year}-${month}-${day}`
                          ? todayTimeslotOptionRender
                          : availableTimeSlot
                      }
                      fullWidth
                    />
                  ) : null}
                </>
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <>
              {formData.appoint_type === 'asap' ? (
                <BooleanInput source="is_emergency" label="急單E-call" fullWidth />
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
      <h3>訂單詳細內容</h3>
      <p>檢查費不需要選擇, 系統會自動跟據訂單類別加入</p>
      <ArrayInput source="orderItems" label="新增工程項目" translateChoice={false}>
        <SimpleFormIterator>
          <CompanyServiceItemRemoteSelectInput
            source="site_service_item_id"
            label="工程項目"
            remoteurl="/value/company-service-items"
            idproperty="id"
            valueproperty="itemName"
            validate={required()}
            alwaysOn
            fullWidth={false}
          />
          <NumberInput source="qty" label="數量" defaultValue="1" fullWidth={false} />
          <NumberInput source="price" label="自訂價錢" fullWidth={false} />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="allow_dispatch" label="立即派送至師傅APP?" fullWidth initialValue={'1'} />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <>
              {formData.allow_dispatch != '1' ? (
                <WorkerRemoteSelectInput
                  source="assignedWorker"
                  label="預先安排工人 (選擇的工人會直接接單 而且不會派送至師傅APP)"
                  remoteurl="/value/workers"
                  idproperty="id"
                  valueproperty="label"
                  fullWidth
                />
              ) : null}
            </>
          );
        }}
      </FormDataConsumer>
      <h3>訂單補充資料</h3>
      <TextInput source="remark" label="訂單備註欄" placeholder="最大限制字數為30字" fullWidth />
      <ImageInput
        source="remarkImages"
        label="訂單相片"
        accept="image/*"
        labelMultiple="最多請上載3張圖片 (png, jpg)"
        multiple
        fullWidth
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <h3>內部使用</h3>
      <TextInput source="internal_remark" label="內部備註欄" placeholder="只限內部使用" fullWidth />
      <BooleanInput source="send_whatsapp_notification_to_client" label="發送客戶Whatsapp" defaultValue={1} />
      <BooleanInput source="send_whatsapp_notification_to_worker" label="發送師傅Whatsapp" defaultValue={1} />
    </SimpleForm>
  );
};

export default OrderCreateForm;
