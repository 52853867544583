import RemoteSelectInput from "components/RemoteSelectInput";
import { DeleteButton, Edit, InputPropTypes, SaveButton, SimpleForm, Toolbar, required } from "react-admin"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});


const JobEditForm = (props) => {
    return (
      <SimpleForm {...props}>
        <RemoteSelectInput
          source="status"
          label="狀態"
          remoteurl="/value/job-status-code"
          idproperty="code"
          valueproperty="name"
          validate={required()}
          alwaysOn
          fullWidth
        />
      </SimpleForm>
    )
}

const JobEditEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton label="儲存" />
    <DeleteButton label="刪除" undoable={false} />
  </Toolbar>
);

const JobEdit = (props) => {
    return (
        <Edit {...props}>
          <JobEditForm toolbar={<JobEditEditToolbar />} />
        </Edit>
    )
}

export default JobEdit