// in src/App.js
import * as React from 'react';
import authProvider from 'providers/authProvider';
import dataProvider from 'providers/dataProvider';
import myTheme from 'myTheme';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import EngineeringIcon from '@material-ui/icons/DeveloperMode';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StoreIcon from '@material-ui/icons/Store';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import Whatshot from '@material-ui/icons/Whatshot';
import CategoryIcon from '@material-ui/icons/Category';
import WidgetsIcon from '@material-ui/icons/Widgets';
import Receipt from '@material-ui/icons/Receipt';
import Tag from '@material-ui/icons/Label';
import AttachMoney from '@material-ui/icons/AttachMoney';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { Admin, Resource } from 'react-admin';
import { AdminUserList, AdminUserShow, AdminUserEdit, AdminUserCreate } from 'resource/adminUsers';
import { SiteUserShow, SiteUserEdit, SiteUserCreate } from 'resource/company/siteUsers';
import { CompanyList, CompanyShow, CompanyEdit, CompanyCreate } from 'resource/company/company';
import { CompanyServiceItemEdit, CompanyServiceItemCreate } from 'resource/company/companyServiceItem';
import { WorkerServiceItemEdit, WorkerServiceItemCreate } from 'resource/worker/workerServiceItem';
import { WorkerLicenseEdit, WorkerLicenseCreate } from 'resource/workerLicense';
import { SiteShow, SiteEdit, SiteCreate } from 'resource/company/site';
import { WorkerUserList, WorkerUserShow, WorkerUserEdit, WorkerUserCreate } from 'resource/worker/workerUsers';
import { WorkerProfileEdit } from 'resource/worker/workerProfile';
import { WorkerSkillsetCreate, WorkerSkillsetEdit } from 'resource/worker/workerSkillset';
import { WorkerProfilePicEdit } from 'resource/worker/workerProfilePic';
import OrderList from 'resource/order/OrderList';
import OrderCreate from 'resource/order/OrderCreate';
import OrderEdit from 'resource/order/OrderEdit';
import OrderShow from 'resource/order/OrderShow';

import Dashboard from 'Dashboard';
import IntegratedDashboard from 'resource/dashboard';
import OrderItemEdit from 'resource/orderItem/OrderItemEdit';
import OrderItemCreate from 'resource/orderItem/OrderItemCreate';
import DefectOrderCreate from 'resource/defectOrder/DefectOrderCreate';
import DefectOrderShow from 'resource/defectOrder/DefectOrderShow';
import DefectOrderList from 'resource/defectOrder/DefectOrderList';
import DefectOrderEdit from 'resource/defectOrder/DefectOrderEdit';

import JobList from 'resource/job/JobList';
import JobShow from 'resource/job/JobShow';

import ServiceItemList from 'resource/serviceItems/ServiceItemList';
import ServiceItemCreate from 'resource/serviceItems/ServiceItemCreate';
import ServiceItemEdit from 'resource/serviceItems/ServiceItemEdit';

import NroRequestList from 'resource/nroRequest/NroRequestList';
import NroRequestEdit from 'resource/nroRequest/NroRequestEdit';
import NroRequestShow from 'resource/nroRequest/NroRequestShow';
import SiteRecipientsCreate from 'resource/siteRecipients/SiteRecipientsCreate';
import NroRequestDetailsEdit from 'resource/NroRequestDetails/NroRequestDetailsEdit';

import ServiceItemCategoryList from 'resource/serviceItemCategory/serviceItemCategoryList';
import ServiceItemCategoryEdit from 'resource/serviceItemCategory/serviceItemCategoryEdit';
import ServiceItemCategoryCreate from 'resource/serviceItemCategory/serviceItemCategoryCreate';
import ServiceItemSubCategoryList from 'resource/serviceItemSubCategory/serviceItemSubCategoryList';
import ServiceItemSubCategoryEdit from 'resource/serviceItemSubCategory/serviceItemSubCategoryEdit';
import ServiceItemSubCategoryCreate from 'resource/serviceItemSubCategory/serviceItemSubCategoryCreate';
import JobItemEdit from 'resource/jobItem/JobItemEdit';

import TransactionCreate from 'resource/Transactions/TransactionCreate';
import PaymentCreate from 'resource/payment/PaymentCreate';
import PaymentEdit from 'resource/payment/PaymentEdit';
import TransactionEdit from 'resource/Transactions/TransactionEdit';
import JobItemCreate from 'resource/jobItem/JobItemCreate';
import CompanyStatementList from 'resource/companyStatement/CompanyStatementList';
import CompanyStatementCreate from 'resource/companyStatement/CompanyStatementCreate';
import CompanyStatementShow from 'resource/companyStatement/CompanyStatementShow';
import CompanyStatementEdit from 'resource/companyStatement/CompanyStatementEdit';
import WorkerStatementList from 'resource/workerStatement/WorkerStatementList';
import WorkerStatementCreate from 'resource/workerStatement/WorkerStatementCreate';
import WorkerStatementShow from 'resource/workerStatement/WorkerStatementShow';
import WorkerStatementEdit from 'resource/workerStatement/WorkerStatementEdit';
import TagList from 'resource/tag/TagList';
import TagCreate from 'resource/tag/TagCreate';
import TagEdit from 'resource/tag/TagEdit';
import SiteWhatsappCreate from 'resource/siteWhatsapp/SiteWhatsappCreate';
import JobEdit from 'resource/job/JobEdit';
import SiteWhatsappEdit from 'resource/siteWhatsapp/SiteWhatsappEdit';
import TagShow from 'resource/tag/TagShow';
import PickupOrderList from 'resource/dashboard/PickedUpOrderList';

const App = () => {
  return (
    <Admin theme={myTheme} dashboard={IntegratedDashboard} authProvider={authProvider} dataProvider={dataProvider}>
      <Resource name="admin/dashboard" />
      <Resource
        name="admin/admin-users"
        list={AdminUserList}
        show={AdminUserShow}
        edit={AdminUserEdit}
        create={AdminUserCreate}
        icon={SupervisorAccountIcon}
        options={{
          label: '管理員',
        }}
      />
      <Resource
        name="admin/worker-users"
        list={WorkerUserList}
        show={WorkerUserShow}
        edit={WorkerUserEdit}
        create={WorkerUserCreate}
        icon={EngineeringIcon}
        options={{
          label: '師傅',
        }}
      />
      <Resource name="admin/worker-profiles" edit={WorkerProfileEdit} />
      <Resource
        name="admin/site-users"
        /*list={SiteUserList}*/
        show={SiteUserShow}
        edit={SiteUserEdit}
        create={SiteUserCreate}
        icon={GroupWorkIcon}
        options={{
          label: '分店用戶',
        }}
      />
      <Resource
        name="admin/companies"
        list={CompanyList}
        show={CompanyShow}
        edit={CompanyEdit}
        create={CompanyCreate}
        icon={BusinessIcon}
        options={{
          label: '公司詳情',
        }}
      />
      <Resource
        name="admin/sites"
        /*list={SiteList}*/
        show={SiteShow}
        edit={SiteEdit}
        create={SiteCreate}
        icon={StoreIcon}
        options={{
          label: '分店',
        }}
      />
      <Resource
        name="code/service-items"
        list={ServiceItemList}
        create={ServiceItemCreate}
        edit={ServiceItemEdit}
        icon={AssignmentIcon}
        options={{
          label: '維修項目',
        }}
      />
      <Resource
        name="code/service-item-categories"
        list={ServiceItemCategoryList}
        edit={ServiceItemCategoryEdit}
        create={ServiceItemCategoryCreate}
        icon={CategoryIcon}
        options={{
          label: '維修項目類別',
        }}
      />
      <Resource
        name="code/service-item-sub-categories"
        list={ServiceItemSubCategoryList}
        edit={ServiceItemSubCategoryEdit}
        create={ServiceItemSubCategoryCreate}
        icon={WidgetsIcon}
        options={{
          label: '維修項目子類別',
        }}
      />
      <Resource
        name="admin/orders"
        list={OrderList}
        show={OrderShow}
        create={OrderCreate}
        edit={OrderEdit}
        icon={StarBorderIcon}
        options={{
          label: '訂單',
        }}
      />
      <Resource
        name="admin/defect-orders"
        list={DefectOrderList}
        show={DefectOrderShow}
        create={DefectOrderCreate}
        edit={DefectOrderEdit}
        icon={BorderAllIcon}
        options={{
          label: '執漏單',
        }}
      />
      <Resource
        name="admin/worker-jobs"
        list={JobList}
        show={JobShow}
        edit={JobEdit}
        options={{
          label: '工程',
        }}
      />
      <Resource
        name="admin/worker-job-items"
        edit={JobItemEdit}
        create={JobItemCreate}
        options={{
          label: '工程項目',
        }}
      />
      <Resource
        name="admin/non-regular-order-item-requests"
        list={NroRequestList}
        edit={NroRequestEdit}
        show={NroRequestShow}
        icon={Whatshot}
        options={{
          label: '加入非常用項目請求',
        }}
      />
      <Resource
        name="admin/company-statements"
        list={CompanyStatementList}
        create={CompanyStatementCreate}
        edit={CompanyStatementEdit}
        show={CompanyStatementShow}
        icon={AttachMoney}
        options={{
          label: '公司月結單',
        }}
      />
      <Resource
        name="admin/worker-statements"
        list={WorkerStatementList}
        create={WorkerStatementCreate}
        edit={WorkerStatementEdit}
        show={WorkerStatementShow}
        icon={Receipt}
        options={{
          label: '師傅工資單',
        }}
      />
      <Resource
        name="admin/tags"
        list={TagList}
        create={TagCreate}
        edit={TagEdit}
        show={TagShow}
        icon={Tag}
        options={{
          label: '分店標籤',
        }}
      />
      <Resource name="admin/tag-pics" />
      <Resource
        name="admin/site-whatsapps"
        icon={WhatsAppIcon}
        create={SiteWhatsappCreate}
        edit={SiteWhatsappEdit}
        options={{
          label: '分店Whatsapp通知',
        }}
      />
      <Resource name="admin/non-regular-order-item-request-details" edit={NroRequestDetailsEdit} />
      <Resource name="admin/site-recipients" create={SiteRecipientsCreate} />
      <Resource name="admin/order-items" edit={OrderItemEdit} create={OrderItemCreate} />
      <Resource name="admin/company-service-items" edit={CompanyServiceItemEdit} create={CompanyServiceItemCreate} />
      <Resource name="admin/company-service-item-histories" />
      <Resource name="admin/worker-service-items" edit={WorkerServiceItemEdit} create={WorkerServiceItemCreate} />
      <Resource name="admin/worker-service-item-histories" />
      <Resource name="admin/worker-licenses" edit={WorkerLicenseEdit} create={WorkerLicenseCreate} />
      <Resource name="admin/worker-profile-pics" edit={WorkerProfilePicEdit} />
      <Resource name="admin/order/order-history" />
      <Resource name="admin/order/order-remark-images" />
      <Resource name="admin/worker-skillsets" create={WorkerSkillsetCreate} edit={WorkerSkillsetEdit} />
      <Resource name="code/business-natures" />
      <Resource name="admin/worker-job/single-order-job" />
      <Resource name="admin/payments" create={PaymentCreate} edit={PaymentEdit} />
      <Resource name="admin/transactions" create={TransactionCreate} edit={TransactionEdit} />
      <Resource name="admin/discount-rates" />
      <Resource name="admin/dashboard/picked-up" />
      <Resource name="admin/dashboard/pending-pickup-order" />
      <Resource name="admin/dashboard/waiting-nro" />
      <Resource name="admin/dashboard/completed-not-verify" />
      <Resource name="admin/dashboard/in-progress" />
    </Admin>
  );
};
export default App;
